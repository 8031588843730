import { Component, OnInit } from '@angular/core';
import { User } from '../../const';
import { UsersService } from './users.service';
import { NzMessageService } from 'ng-zorro-antd';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  ValidationErrors
} from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { find } from 'lodash';

@Component({
  selector: 'solar-badget-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  users: User[] = [];
  user: User;
  validateForm: FormGroup;
  isVisible = false;
  isConfirmLoading = false;
  nzFooter = null;
  editing;

  loading = true;

  roles = [
    {
      name: 'User',
      value: 'user'
    },
    {
      name: 'Admin',
      value: 'admin'
    }
  ];

  constructor(
    private usersService: UsersService,
    private nzMessageService: NzMessageService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadUsers();
    this.validateForm = this.fb.group({
      id: [''],
      name: ['', [Validators.required], [this.nameAsyncValidator]],
      email: [
        '',
        [Validators.email, Validators.required],
        [this.mailAsyncValidator]
      ],
      phone: ['', [Validators.required]],
      role: ['user'],
      password: [''],
      confirm: ['', [this.confirmValidator]],
      active: ['']
    });
  }

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.validateForm.controls.confirm.updateValueAndValidity()
    );
  }

  nameAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        if (
          find(this.users, (user: User) => {
            return user.name === control.value && user.name !== this.user.name;
          })
        ) {
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          observer.next(null);
        }
        observer.complete();
      }, 300);
    });

  mailAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        if (
          find(this.users, (user: User) => {
            return (
              user.email === control.value && user.email !== this.user.email
            );
          })
        ) {
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          observer.next(null);
        }
        observer.complete();
      }, 300);
    });

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (
      !this.validateForm ||
      !this.validateForm.controls.password ||
      !this.validateForm.controls.password.value
    ) {
      return {};
    } else if (
      control &&
      control.value !== this.validateForm.controls.password.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  private loadUsers() {
    this.usersService.all().subscribe((users: User[]) => {
      this.users = users;
      this.loading = false;
    });
  }

  cancel(): void {
    this.isVisible = false;
  }

  add() {
    this.editing = false;
    this.isConfirmLoading = false;
    this.validateForm.reset();
    this.isVisible = true;
  }

  edit(user: User) {
    this.editing = true;
    this.isConfirmLoading = false;
    this.validateForm.reset();
    this.user = user;

    this.validateForm.patchValue({
      id: user.id,
      name: user.name,
      role: user.role,
      phone: user.phone,
      email: user.email,
      active: user.active
    });

    this.isVisible = true;
  }

  update(user: User) {
    this.isConfirmLoading = true;
    this.usersService.update(user).subscribe(
      (res: { message: string }) => {
        this.nzMessageService.info(res.message);
        this.isConfirmLoading = false;
        this.isVisible = false;
        this.loadUsers();
      },
      error => {}
    );
  }

  active(user: User) {
    this.usersService
      .toggleActive(user)
      .subscribe((res: { message: string }) => {
        this.nzMessageService.info(res.message);
        this.loadUsers();
      });
  }
}
