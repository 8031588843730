<div style="display: flex; flex-direction: row;">
  <div style="display: inline-block; flex: 1"></div>
  <div style="display: inline-block;">
    <div style="display: inline-block;">
      <a
        [ngClass]="{ inactive: !canView }"
        nz-button
        nzType="link"
        (click)="viewBudget()"
        routerLinkActive="active-link"
      >
        VER <i nz-icon nzType="eye" nzTheme="outline"></i
      ></a>
    </div>
    <div style="display: inline-block;">
      <a
        style="display: inline-block;"
        [ngClass]="{ inactive: !canSave || saving }"
        nz-button
        nzType="link"
        (click)="saveBudget()"
        routerLinkActive="active-link"
      >
        GUARDAR <nz-spin *ngIf="saving" style="display: inline-block;margin-left: 5px;" nzSimple></nz-spin
        ><i *ngIf="!saving" nz-icon nzType="save" nzTheme="outline"></i
      ></a>
    </div>
    <div style="display: inline-block;">
      <a
        nz-button
        nzType="link"
        (click)="newBudget()"
        routerLinkActive="active-link"
      >
        NUEVO <i nz-icon nzType="plus-square" nzTheme="outline"></i
      ></a>
    </div>
    <nz-dropdown-menu #menu2="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item class="main-text">
          <a
            href="https://re.jrc.ec.europa.eu/pvg_tools/en/tools.html#PVP"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i nz-icon nzType="heat-map" nzTheme="outline"></i>

            Hourly radiation</a
          >
        </li>
        <li nz-menu-item class="main-text">
          <a
            href="https://accounts.solaredge.com/solaredge-webapp/#/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i nz-icon nzType="api" nzTheme="outline"></i>

            Solar Edge</a
          >
        </li>
      </ul>
    </nz-dropdown-menu>
    <nz-button-group>
      <a nz-button nz-dropdown [nzDropdownMenu]="menu2" nzType="link"
        >HERRAMIENTAS <i nz-icon nzType="tool"></i
      ></a>
    </nz-button-group>

    <solar-badget-user-avatar></solar-badget-user-avatar>
  </div>
</div>
