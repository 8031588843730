import { Component, OnInit, Input } from '@angular/core';

export var single = [
  {
    name: 'Gasto Pasado',
    value: 8940000
  },
  {
    name: 'Gasto Futuro',
    value: 5000000
  }
];

@Component({
  selector: 'solar-badget-summary-graph-financial-yearly',
  templateUrl: './summary-graph-financial-yearly.component.html',
  styleUrls: ['./summary-graph-financial-yearly.component.scss']
})
export class SummaryGraphFinancialYearlyComponent implements OnInit {
  single: any[];
  view: any[] = [600, 400];

  @Input() data;

  graphData;

  label = 'Evolución de los gastos anuales en €';

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  euroFormatting = (value: number) => `${value} €`;

  percentageFormatting = (value: number) => `${value} %`;

  colorScheme = {
    domain: ['#E9483F', '#59C3E3', '#C1D100']
  };

  constructor() {
    Object.assign(this, { single });
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  ngOnInit(): void {
    this.graphData = [
      {
        name: 'Gasto Pasado',
        value: this.data.gastoPasado
      },
      {
        name: 'Gasto Futuro',
        value: this.data.gastoFuturo > 0 ? this.data.gastoFuturo : 0
      }
    ];
  }
}
