<div style="margin-left:10px; display:inline-block"></div>

<nz-button-group>
  <button nz-button>{{ name }}</button>
  <button
    nz-button
    nz-dropdown
    [nzDropdownMenu]="menu2"
    nzPlacement="bottomRight"
  >
    <i nz-icon nzType="user"></i>
  </button>
</nz-button-group>

<nz-dropdown-menu #menu2="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="logout()"><i nz-icon nzType="logout" nzTheme="outline"></i> desconectar</li>
  </ul>
</nz-dropdown-menu>
