import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  StoreService,
  SlopeOption,
  SlopeData
} from '../../../shared/store-service.service';

import { last, find } from 'lodash';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'solar-badget-badget-production',
  templateUrl: './badget-production.component.html',
  styleUrls: ['./badget-production.component.scss']
})
export class BadgetProductionComponent implements OnInit {
  productionForm: FormGroup;

  slopesData: SlopeData[] = [{ id: 0 }, { id: 1 }];

  suffixIconButton = 'save';

  slopesOptions: SlopeOption[] = [
    { value: 1, label: '1 vertiente' },
    { value: 2, label: '2 vertientes' }
  ];

  slopesSelected = [];

  slopes = null;

  showBatteryKWh = false;

  constructor(private fb: FormBuilder, private store: StoreService) {}

  slopeChanges(slopeData: SlopeData, pos) {
    this.slopesData[pos] = slopeData;
    this.store.setSlopes(this.slopesData);
  }

  slopeSelected(sel) {
    if (!sel) {
      return;
    }
    if (sel.value === 1) {
      this.slopesData[1] = { id: 1 };
      this.store.setSlopes(this.slopesData);
    }
  }

  ngOnInit(): void {
    this.productionForm = this.fb.group({
      slopes: [this.slopesOptions[0], [Validators.required]],
      battery: [],
      batterykWh: [],
      numberPanels: [1],
      acPower: []
    });

    if (this.store.slopesData) {
      let id = 0;
      this.slopesData = this.store.slopesData;
      if (this.slopesData[1].installed_peak_power) {
        id = 1;
      }
      this.slopes = this.slopesOptions[id];
    } else {
      this.slopes = this.slopesOptions[0];
    }

    if (this.store.bateryData) {
      this.productionForm.patchValue({
        battery: this.store.bateryData.battery,
        batterykWh: this.store.bateryData.batterykWh,
        numberPanels: this.store.bateryData.numberPanels,
        acPower: this.store.bateryData.acPower
      });
    }

    this.productionForm.valueChanges.subscribe(values => {
      this.slopes = values.slopes;
      this.showBatteryKWh = false;

      if (values.battery === 'true') {
        this.showBatteryKWh = true;
      }

      this.store.setBattery({
        battery: values.battery,
        batterykWh: values.batterykWh,
        numberPanels: values.numberPanels,
        acPower: values.acPower
      });
    });
  }
}
