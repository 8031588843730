<div class="site-page-header-ghost-wrapper">
  <nz-page-header nzBackIcon [nzGhost]="false" (nzBack)="onBack()">
    <nz-page-header-title
      ><span style="cursor: pointer;" (click)="onBack()"
        >volver</span
      ></nz-page-header-title
    >
    <nz-page-header-subtitle></nz-page-header-subtitle>
    <nz-page-header-extra>
      <span class="radiation">
        <!-- <button
          [disabled]="calculating"
          class="radiation-el"
          (click)="calculate()"
          nz-button
          nzType="primary"
        >
          Calcular radiación
        </button> -->
        <button
          [disabled]="calculating || tabNumber === 1"
          nz-button
          nzType="primary"
          (click)="htmltoPDF()"
        >
          <i nz-icon nzType="search"></i> Exportar a pdf
        </button>
      </span>
    </nz-page-header-extra>
    <nz-page-header-content> </nz-page-header-content>
  </nz-page-header>
</div>

<nz-tabset (nzSelectedIndexChange)="tabIndex($event)">
  <nz-tab nzTitle="Informe">
    <div
      *ngIf="calculating"
      style="display: flex; flex-direction: column; justify-content: stretch;"
    >
      <nz-result
        nzStatus="info"
        nzTitle="Generando informe"
        nzSubTitle="Descargando datos de radición y calculando.."
      >
        <div nz-result-extra>
          <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
        </div>
      </nz-result>
    </div>

    <div
      *ngIf="radiationResults"
      id="parentdiv"
      #reportContent
      [ngClass]="{ web: showUploads, print: !showUploads }"
    >
      <solar-badget-badget-result-header
        id="header"
      ></solar-badget-badget-result-header>
      <div class="page" id="page1">
        <div class="page-content">
          <div class="main-title">
            Te presentamos el autoconsumo a tu medida
          </div>

          <!-- RESUMEN -->
          <div class="summary-content text">
            <!-- CLIENTE -->
            <div>
              <span class="secondary-subtitle">Cliente:</span>
              {{ data.customerData?.name }}
              {{ data.customerData?.surname }}
            </div>

            <!-- DIRECCIÓN -->
            <div>
              <span class="secondary-subtitle">Dirección:</span>
              {{ data.customerData?.address }}
            </div>

            <!-- OFERTA COMERCIAL -->
            <div>
              <span class="secondary-subtitle">Oferta Comercial:</span>
              {{ data.customerData?.opportunity }}
            </div>
          </div>

          <div class="combo-img-h2 combo-title">
            <img
              class="img-secondary-title"
              src="/assets/informe/iconos/Iconos-Informe-Elívere-01.png"
            />
            <div class="secondary-title combo-title">Nuestra Propuesta</div>
          </div>

          <div class="indented">
            <div class="secondary-subtitle">
              EN RESUMEN
            </div>

            <div
              class="info-text"
              *ngIf="
                data.bateryData?.battery && data.bateryData?.battery !== 'false'
              "
            >
              {{ textWithBattery }}:
            </div>
            <div
              class="info-text"
              *ngIf="
                !data.bateryData?.battery ||
                data.bateryData?.battery === 'false'
              "
            >
              {{ textWithoutBattery }}:
            </div>

            <div class="secondary-subtitle">
              EN DATOS
            </div>
            <nz-switch
              style="padding-bottom:20px"
              *ngIf="showUploads"
              [(ngModel)]="showImage"
              nzCheckedChildren="Sin imagen"
              nzUnCheckedChildren="Con imagen"
            ></nz-switch>
            <div
              *ngIf="!showImage"
              class="d3-info text"
              style="margin: 40px 0 0 20px"
            >
              <table style="width:900px">
                <tr>
                  <td style="width:525px" class="title-content">
                    Cantidad de paneles:
                  </td>
                  <td style="width:100px"></td>
                  <td class="content-d3" style="font-size: 24pt;">
                    {{ data.bateryData?.numberPanels }} Paneles
                  </td>
                </tr>
                <tr>
                  <td class="title-content">Potencia instalada:</td>
                  <td></td>
                  <td class="content-d3" style="font-size: 24pt;">
                    {{ installed_peak_power }} KWp
                  </td>
                </tr>
                <tr
                  *ngIf="
                    data.bateryData?.battery &&
                    data.bateryData?.battery === 'true'
                  "
                >
                  <td class="title-content">Capacidad de la batería:</td>
                  <td></td>
                  <td class="content-d3" style="font-size: 24pt;">
                    {{ data.bateryData?.batterykWh }} KWh
                  </td>
                </tr>
                <tr *ngIf="data.bateryData?.acPower">
                  <td class="title-content">Potencia nominal en AC:</td>
                  <td></td>
                  <td class="content-d3" style="font-size: 24pt;">
                    {{ data.bateryData?.acPower }} KW
                  </td>
                </tr>
              </table>
            </div>

            <div *ngIf="showImage">
              <img
                style="max-width: 50%; display: inline-block;"
                [src]="images.d3.url"
                width="600px"
              />
              <div
                style="max-width: 50%; display: inline-block; vertical-align: bottom;"
              >
                <ul class="d3-info text">
                  <li>
                    <div class="info-text">Cantidad de paneles</div>

                    <div
                      class="editable"
                      *ngIf="!numOfPanels"
                      (click)="numOfPanels = true"
                    >
                      <span class="content-d3 secondary-text"
                        >{{ data.bateryData?.numberPanels }} Paneles</span
                      >
                    </div>
                    <input
                      *ngIf="numOfPanels"
                      nz-input
                      placeholder="Basic usage"
                      (keyup.enter)="numOfPanels = false"
                      [(ngModel)]="panels"
                    />
                  </li>
                  <li>
                    <div class="info-text">Potencia instalada:</div>
                    <div
                      class="editable"
                      *ngIf="!installedPower"
                      (click)="installedPower = true"
                    >
                      <div class="content-d3">
                        {{ installed_peak_power }} KWp
                      </div>
                    </div>
                    <input
                      *ngIf="installedPower"
                      nz-input
                      placeholder="Basic usage"
                      (keyup.enter)="installedPower = false"
                      [(ngModel)]="power"
                    />
                  </li>
                  <li
                    *ngIf="
                      data.bateryData?.battery &&
                      data.bateryData?.battery === 'true'
                    "
                  >
                    <div class="info-text">Capacidad de la batería</div>
                    <div class="content-d3">
                      {{ data.bateryData?.batterykWh }} KWh
                    </div>
                  </li>
                  <li *ngIf="data.bateryData?.acPower">
                    <div class="info-text">Potencia nominal en AC</div>
                    <div
                      class="editable"
                      *ngIf="!nominalPower"
                      (click)="nominalPower = true"
                    >
                      <span class="content-d3"
                        >{{ data.bateryData?.acPower }} KW</span
                      >
                    </div>
                    <input
                      *ngIf="nominalPower"
                      nz-input
                      placeholder="Basic usage"
                      (keyup.enter)="nominalPower = false"
                      [(ngModel)]="nominal"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div *ngIf="showImage">
              <nz-upload
                style="width:40%"
                *ngIf="showUploads"
                nzType="drag"
                nzName="file"
                [nzAction]="apiUploadUrl + '/d3'"
                (nzChange)="handleChange($event, 'd3')"
              >
                <p class="ant-upload-drag-icon">
                  <i nz-icon nzType="inbox"></i>
                </p>
                <p class="ant-upload-text">
                  Pulsa o arrastra un fichero sobre este área
                </p>
              </nz-upload>
            </div>
          </div>
        </div>
      </div>

      <!-- PÁGINA 2 -->
      <div class="page" id="page2">
        <div class="combo-img-h2 combo-title">
          <img
            class="img-secondary-title"
            src="/assets/informe/iconos/Iconos-Informe-Elívere-01.png"
          />
          <div class="secondary-title combo-title">Nuestra Propuesta</div>
        </div>
        <div class="indented">
          <div class="secondary-subtitle">
            CONSUMO Y PRODUCCIÓN
          </div>

          <div class="info-text">
            La siguiente gráfica muestra los valores de producción de energía
            fotovoltaica durante los 12 meses del año y cuánto de esa energía es
            autoconsumida, energía autoconsumida se entiende por la energía
            aportada por la planta fotovoltaica a consumo de la vivienda. El
            resto de la energía se exporta directamente a
            <span class="highlight">LA RED ELÉCTRICA</span>
          </div>

          <!-- GRÁFICA SIN BATERÍA -->

          <solar-badget-summary-graph-battery
            [data]="graphdata"
            [dataGoogle]="graphdataGoogle"
            [radiationData]="radiationData"
            class="summary-graph-no-battery"
          >
          </solar-badget-summary-graph-battery>
        </div>
      </div>

      <!-- PÁGINA 3 -->

      <div class="page" id="page3">
        <div class="combo-img-h2 combo-title">
          <img
            class="img-secondary-title"
            src="/assets/informe/iconos/Iconos-Informe-Elívere-02.png"
          />
          <div class="third-level-title combo-title">
            Los números que importan
          </div>
        </div>
        <div class="indented">
          <div class="secondary-subtitle">
            COMPENSACIÓN SIMPLIFICADA
          </div>

          <div class="info-text">
            La energía exportada a la red se compensa económicamente según los
            precios que ofrezca la empresa comercializadora del cliente. En
            Elivere te recomentamos la mejor alternativa del mercado.
          </div>
          <div class="d3-info text" style="margin: 40px 0 0 20px">
            <table style="width:900px">
              <tr>
                <td style="width:525px" class="title-content">
                  Energía compensada:
                </td>
                <td style="width:100px"></td>
                <td class="content-d3" style="color: #C1D100;font-size: 24pt;">
                  {{ pvp.compensado / pvp.precioVenta | number: '1.0-0' }}
                  kWh
                </td>
              </tr>
              <tr>
                <td class="title-content">Precio de la energía :</td>
                <td></td>
                <td class="content-d3" style="font-size: 24pt;">
                  {{ pvp.precioVenta | number: '1.0-3' }} €/kWh
                </td>
              </tr>
              <tr>
                <td class="title-content">Compensación anual estimada:</td>
                <td></td>
                <td class="content-d3" style="color: #C1D100;font-size: 24pt;">
                  {{ pvp.compensado | number: '1.0-0' }}
                  €/año
                </td>
              </tr>
            </table>
          </div>

          <div class="secondary-subtitle" style="margin-top:75px">
            AHORROS Y GASTOS ANUALES EN CONCEPTO DE ENERGÍA
          </div>
          <table class="d3-info text" style="margin: 40px 0 0 20px;width:900px">
            <tr>
              <td style="width:525px" class="title-content">
                Lo que gastabas:
              </td>
              <td style="color:gray;font-size: 16pt;width:100px">100%</td>
              <td class="content-d3" style="font-size: 24pt;">
                {{ pvp.gastoPasado | number: '1.0-0' }}
                €/año
              </td>
            </tr>
            <tr>
              <td class="title-content">
                Lo que gastarás:
              </td>
              <td style="color:gray;font-size: 16pt;">
                {{ compensacionSimplificada.gastaras.pctg | number: '1.0-0' }}
                %
              </td>
              <td class="content-d3" style="color: #C1D100; font-size: 24pt;">
                {{ pvp.gastoFuturo | number: '1.0-0' }}
                €/año
              </td>
            </tr>
            <tr>
              <td class="title-content">
                Lo que ahorrarás:
              </td>
              <td style="color:gray;font-size: 16pt;">
                {{ compensacionSimplificada.ahorraras.pctg | number: '1.0-0' }}
                %
              </td>
              <td class="content-d3" style="color: #C1D100; font-size: 24pt;">
                {{ pvp.ahorroTotal | number: '1.0-0' }}
                €/año
              </td>
            </tr>
            <tr>
              <td class="title-content">Lo que ahorrarás por mes:</td>
              <td></td>
              <td class="content-d3" style="color: #C1D100;font-size: 24pt;">
                {{ pvp.ahorroTotal / 12 | number: '1.0-0' }}
                €/mes
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- PÁGINA 4 -->

      <div class="page" id="page4">
        <div class="combo-img-h2 combo-title">
          <img
            class="img-secondary-title"
            src="/assets/informe/iconos/Iconos-Informe-Elívere-02.png"
          />
          <div class="third-level-title combo-title">
            Los números que importan
          </div>
        </div>
        <div class="indented">
          <div class="secondary-subtitle">
            RENTABILIDAD DE TU INVERSIÓN
          </div>

          <div class="info-text">
            La rentabilidad de la inversión realizada, nos permite poder evaluar
            cuánto beneficio le está obteniendo al dinero invertido en la
            instalación de Auto-Consumo Fotovoltaico.
          </div>

          <table class="d3-info text" style="margin-top:40px; width: 900px">
            <tr>
              <td style="width: 500px" class="title-content">Inversión:</td>
              <td class="content-d3" style="font-size: 24pt;">
                {{ pvp.inversionTotal | number: '1.0-0' }} €
              </td>
            </tr>
            <tr>
              <td class="title-content">Rentabiliad anual:</td>
              <td class="content-d3" style="font-size: 24pt;">
                {{ pvp.rentabilidadAnual | number: '1.0-0' }} %
              </td>
            </tr>

            <tr>
              <td class="title-content">Recuperación de la inversión:</td>
              <td class="content-d3" style="font-size: 24pt;">
                {{ pvp.recuperacionDeInversion | number: '1.1-1' }} años
              </td>
            </tr>
          </table>

          <div
            style="margin-top: 60px;"
            *ngIf="data.financingData?.financing.value"
          >
            <div class="secondary-subtitle">
              FINANCIACIÓN "PAGA CON LO QUE AHORRAS"
            </div>

            <div class="info-text">
              El valor de la inversión puede ser financiado mediante la
              financiera recomendada por Elivere, los datos aproximados de
              financiación a falta de un estudio financiero, son los siguientes:
            </div>

            <table class="d3-info text" style="margin-top:40px; width: 900px">
              <tr>
                <td style="width: 500px" class="title-content">Inversión:</td>
                <td class="content-d3">{{ pvp.inversion }} €</td>
              </tr>

              <tr>
                <td class="title-content">TAE:</td>
                <td class="content-d3">{{ data.financingData.tae }} %</td>
              </tr>

              <tr>
                <td class="title-content">Entrada inicial:</td>
                <td class="content-d3">{{ data.financingData.entry }} €</td>
              </tr>

              <tr>
                <td class="title-content">Número de cuotas:</td>
                <td class="content-d3">
                  {{ data.financingData?.months.month }}
                </td>
              </tr>

              <tr>
                <td class="title-content">Importe a financiar:</td>
                <td class="content-d3">
                  {{ data.financialResults?.if.value | number: '1.0-0' }}
                  €
                </td>
              </tr>

              <tr>
                <td class="title-content">Valor de la cuota:</td>
                <td class="content-d3">
                  {{ data.financialResults?.cm.value | number: '1.0-0' }}
                  €
                </td>
              </tr>

              <tr>
                <td class="title-content">Inversión final financiado:</td>
                <td class="content-d3">
                  {{ data.financialResults?.ita.value | number: '1.0-0' }}
                  €
                </td>
              </tr>
            </table>

            <i style="margin-top:20px;">
              *** En {{ data.financingData?.months.month }} cuotas con entrada
              de {{ data.financingData.entry }}. TAE 6%, con servicio de
              mantenimiento durante
              {{ data.financingData?.months.month / 12 }} años incluído. Sujeto
              a aprobación financiera. Importe a financiar
              {{ data.financialResults?.if.value | number: '1.0-0' }}€, Importe
              total adeudado
              {{ data.financialResults?.ita.value | number: '1.0-0' }}€, precio
              total a plazos
              {{ data.financialResults?.ptp.value | number: '1.0-0' }}€.
            </i>
          </div>
        </div>
      </div>

      <!-- PÁGINA 5 -->

      <div class="page" id="page5">
        <div class="combo-img-h2 combo-title">
          <img
            class="img-secondary-title"
            src="/assets/informe/iconos/Iconos-Informe-Elívere-03.png"
          />
          <div class="social-text secondary-title combo-title">
            Tu contribución al planeta
          </div>
        </div>
        <div class="indented">
          <div class="secondary-subtitle">
            AHORRO MEDIOAMBIENTAL
          </div>

          <div class="info-text">
            Conoce el impacto en el medioambiente que conseguirás instalando
            energía solar fotovoltaica
          </div>

          <div style="max-width: 50%; vertical-align: bottom; margin-top: 35px">
            <table
              class="d3-info text"
              style="width: 800px; margin: 0;padding: 0;"
            >
              <tr>
                <td>
                  <img
                    src="/assets/informe/images/Icono-Contribución-Energía.png"
                  />
                </td>
                <td class="title-content">Energía anual limpia generada:</td>

                <td>
                  <span class="social-text content-d3"
                    >{{
                      radiationResults.totalproductionKwYear | number: '1.0-0'
                    }}
                    KWh/año</span
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    src="/assets/informe/images/Icono-Contribución-CO2.png"
                  />
                </td>
                <td class="title-content">Ahorro anual de Kg. en CO2:</td>
                <td>
                  <span class="social-text content-d3"
                    >{{ co2 | number: '1.0-0' }} Kg./año</span
                  >
                </td>
              </tr>
              <tr>
                <div>
                  <img
                    src="/assets/informe/images/Icono-Contribución-Árboles.png"
                  />
                </div>
                <td class="title-content">Árboles no talados al año:</td>
                <td>
                  <span class="social-text content-d3"
                    >{{ trees | number: '1.0-0' }} árboles</span
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    src="/assets/informe/images/Icono-Contribución-Kilómetros.png"
                  />
                </td>
                <td class="title-content">KM al año con un coche eléctrico:</td>
                <td>
                  <span class="social-text content-d3 social"
                    >{{ cars | number: '1.0-0' }} Kms.</span
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div style="margin-top: 100px" class="main-title">
          Gracias por apostar por el cambio de fase
        </div>

        <!-- RESUMEN -->
        <div class="summary-content text" style="margin-left: 40px">
          <!-- CLIENTE -->
          <div>
            <span class="secondary-subtitle">TU ASESOR COMERCIAL: </span>
            <div
              class="editable"
              *ngIf="!comercialName"
              (click)="comercialName = true"
            >
              <span class="secondary-subtitle">{{ user.name }}</span>
              <i
                *ngIf="!calculating"
                nz-icon
                nzType="edit"
                nzTheme="outline"
              ></i>
            </div>
            <input
              *ngIf="comercialName"
              nz-input
              placeholder="Basic usage"
              (keyup.enter)="comercialName = false"
              [(ngModel)]="user.name"
            />
            <button
              *ngIf="comercialName"
              nz-button
              (click)="comercialName = false"
            >
              Aceptar
            </button>
          </div>

          <!-- DIRECCIÓN -->
          <div>
            <span class="secondary-subtitle">TELÉFONO: </span>
            <div
              class="editable"
              *ngIf="!comercialPhone"
              (click)="comercialPhone = true"
            >
              <span class="secondary-subtitle">{{ user.phone }}</span>
              <i
                *ngIf="!calculating"
                nz-icon
                nzType="edit"
                nzTheme="outline"
              ></i>
            </div>
            <input
              *ngIf="comercialPhone"
              nz-input
              placeholder="Basic usage"
              (keyup.enter)="comercialPhone = false"
              [(ngModel)]="user.phone"
            />
            <button
              *ngIf="comercialPhone"
              nz-button
              (click)="comercialPhone = false"
            >
              Aceptar
            </button>
          </div>

          <!-- OFERTA COMERCIAL -->
          <div>
            <span class="secondary-subtitle">MAIL: </span>
            <div
              class="editable"
              *ngIf="!comercialMail"
              (click)="comercialMail = true"
            >
              <span class="secondary-subtitle">{{ user.email }}</span>
              <i
                *ngIf="!calculating"
                nz-icon
                nzType="edit"
                nzTheme="outline"
              ></i>
            </div>
            <input
              *ngIf="comercialMail"
              nz-input
              placeholder="Basic usage"
              (keyup.enter)="comercialMail = false"
              [(ngModel)]="user.email"
            />
            <button
              *ngIf="comercialMail"
              nz-button
              (click)="comercialMail = false"
            >
              Aceptar
            </button>
          </div>

          <!-- MENSAJE COMERCIAL -->
          <div>
            <span *ngIf="!calculating" class="secondary-subtitle"
              >Mensaje comercial:
            </span>
            <div
              class="editable"
              *ngIf="!comercialMessage"
              (click)="comercialMessage = true"
            >
              <span class="secondary-subtitle">{{ user.commercial }}</span>
              <i
                *ngIf="!calculating"
                nz-icon
                nzType="edit"
                nzTheme="outline"
              ></i>
            </div>
            <textarea
              nz-input
              *ngIf="comercialMessage"
              rows="8"
              placeholder="mensaje comercial para el cliente"
              [(ngModel)]="user.commercial"
            ></textarea>
            <button
              *ngIf="comercialMessage"
              nz-button
              (click)="comercialMessage = false"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
      <solar-badget-badget-result-footer
        id="footer"
      ></solar-badget-badget-result-footer>
    </div>
  </nz-tab>
  <nz-tab nzTitle="Info">
    <div [style.height.px]="heightInfo" style="overflow:auto;">
      <solar-badget-quote-info
        [radiationResults]="radiationResults"
        [badgetData]="data"
        [pvp]="pvp"
      ></solar-badget-quote-info>
      <!--
      <nz-tabset
        style="margin-top: 50px;"
        *ngIf="radiationResults && radiationResults.slope2"
      >
        <nz-tab nzTitle="Vertiente 1">
          <solar-badget-quote-info
            [radiationResults]="radiationResults.slope1"
          ></solar-badget-quote-info>
        </nz-tab>
        <nz-tab *ngIf="radiationResults.slope2" nzTitle="Vertiente 2">
          <solar-badget-quote-info
            [radiationResults]="radiationResults.slope2"
          ></solar-badget-quote-info>
        </nz-tab>
      </nz-tabset> -->
    </div>
  </nz-tab>
</nz-tabset>
