import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, shareReplay } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { unitOfTime } from 'moment';
// Generated by https://quicktype.io
// Generated by https://quicktype.io
import { environment } from '../../environments/environment';
import { StoreService } from '../shared/store-service.service';
export interface AuthResult {
  access_token: string;
  token_type: string;
  expires_in: number;
  user: User;
}

export interface User {
  id?: number;
  name?: string;
  email?: string;
  email_verified_at?: null;
  created_at?: null;
  updated_at?: null;
}

@Injectable()
export class AuthService {
  private user$ = new BehaviorSubject<User>({});

  user;

  constructor(private http: HttpClient, private store: StoreService) {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.user$.next(this.user);
    }
  }

  login(email: string, password: string) {
    return this.http
      .post(environment.api_url + '/api/auth/login', { email, password })
      .pipe(
        tap((res: any) => this.setSession(res)),
        shareReplay()
      );
  }

  private setSession(authResult: AuthResult) {
    this.user = authResult.user;
    localStorage.setItem('user', JSON.stringify(authResult.user));
    localStorage.setItem('access_token', JSON.stringify(authResult.access_token));
    localStorage.setItem('token_type', authResult.token_type);
    const units: unitOfTime.DurationConstructor = 'seconds';
    localStorage.setItem(
      'expires_in',
      moment()
        .add(authResult.expires_in, units)
        .unix()
        .toString()
    );

    this.user$.next(this.user);
  }

  userChanges() {
    return this.user$.asObservable();
  }

  logout() {
    this.store.reset();
    localStorage.removeItem('budget');
    localStorage.removeItem('access_token');
    localStorage.removeItem('token_type');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('user');
    this.user$.next({});
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    return moment(JSON.parse(localStorage.getItem('expires_in')) * 1000);
  }
}
