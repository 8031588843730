<div class="title">PRODUCCIÓN DEL SISTEMA</div>

<ngx-charts-bar-horizontal-stacked
  *ngIf="graphData"
  [view]="view"
  [scheme]="colorScheme"
  [results]="graphData"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  (select)="onSelect($event)"
>
</ngx-charts-bar-horizontal-stacked>

<span class="secondary-text left-data">{{ consumedPctg | number: '1.0-0' }} %</span>
<span class="third-level-text right-data"
  >{{ exportedPctg | number: '1.0-0' }} %</span
>

<div style="margin-left: 40px;margin-top: -30px">
  <div>
    <div>PRODUCCIÓN: <span class="main-text">100%</span></div>
    <span class="main-text data"
      >{{ data[0].totalproductionKwYear | number: '1.0-0' }} kWh</span
    >
  </div>
  <div>
    <div>
      AUTOCONSUMO:
      <span class="secondary-text">{{ consumedPctg | number: '1.0-0' }} %</span>
    </div>
    <span class="secondary-text data"
      >{{
        data[0].totalDemandaKwYear - data[0].importadoDeLaRed | number: '1.0-0'
      }}
      kWh</span
    >
  </div>
  <div>
    <div>
      EXPORTADO
      <span class="third-level-text"
        >{{ exportedPctg | number: '1.0-0' }} %</span
      >
    </div>
    <span class="third-level-text data"
      >{{ data[0].vertidoAnual | number: '1.0-0' }} kWh</span
    >
  </div>
</div>
