import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'lodash';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { cloneDeep, filter, find } from 'lodash';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription, of } from 'rxjs';
import { debounceTime, concatMap } from 'rxjs/operators';
import { StoreService } from '../../shared/store-service.service';
import { UsersService } from '../../users/users/users.service';
import { NzMessageService } from 'ng-zorro-antd';

interface HistoricData {
  id: number;
  opportunity: string;
  customer: string;
  address: string;
  budget: string;
  doc?: any;
  deleted: number;
  updated_at: string;
  images: any[];
  created_at: string;
}

@Component({
  selector: 'solar-badget-badget-history',
  templateUrl: './badget-history.component.html',
  styleUrls: ['./badget-history.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgetHistoryComponent implements OnInit, OnDestroy {
  initLoading = false;
  loadingMore = false;
  loadMore = false;
  data: any[] = [];
  gutter = 10;
  dateRange: [];
  users;
  loading = true;

  list: HistoricData[] = [];

  private _list: HistoricData[] = [];

  searchForm: FormGroup;

  private sub: Subscription;

  @ViewChild('downloadZipLink') private downloadZipLink: any;

  nzScroll;

  listOfColumn = [
    {
      title: 'Cliente',
      compare: (a: any, b: any) => {
        return a.customer < b.customer;
      },
      width: 300
    },
    {
      title: 'Oportunidad',
      compare: (a: any, b: any) => a.opportunity < b.opportunity,
      priority: 1,
      width: 200
    },
    {
      title: 'Creado',
      compare: (a: any, b: any) => {
        return moment(a.created_at, 'DD-MM-YYYY HH:mm').isBefore(
          moment(b.created_at, 'DD-MM-YYYY HH:mm')
        );
      },
      priority: 1,
      width: 200
    },
    {
      title: 'Actualizado',
      compare: (a: any, b: any) => {
        return moment(a.updated_at, 'DD-MM-YYYY HH:mm').isBefore(
          moment(b.updated_at, 'DD-MM-YYYY HH:mm')
        );
      },
      priority: 1,
      width: 200
    },
    {
      title: 'Usuario',
      compare: (a: any, b: any) => a.user.name < b.user.name,
      priority: 1,
      width: 200
    },
    {
      title: '',
      priority: 1,
      width: null
    },
    {
      title: '',
      priority: 1,
      width: 120
    }
  ];

  constructor(
    private http: HttpClient,
    private store: StoreService,
    private change: ChangeDetectorRef,
    private router: Router,
    private usersService: UsersService,
    private fb: FormBuilder,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.getData();

    this.searchForm = this.fb.group({
      search: [null]
      // dateRange: [null]
    });

    this.sub = this.searchForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe(res => {
        if (res.search) {
          this.filterListByPattern(res.search);
        } else {
          this.resetList();
        }
      });
    const y = window.screen.height - 430;
    this.nzScroll = {  y: y + 'px' };
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getData(): void {
    this.loading = true;
    this.usersService
      .allUsers()
      .pipe(
        concatMap((users: any) => {
          this.users = users;
          return this.http.get(environment.api_url + '/api/budgets');
        })
      )
      .subscribe((budgetList: HistoricData[]) => {
        this.loading = false;
        const list = map(budgetList, budget => {
          budget.created_at = moment(budget.created_at).format(
            'DD-MM-YYYY HH:mm'
          );
          budget.updated_at = moment(budget.updated_at).format(
            'DD-MM-YYYY HH:mm'
          );

          budget.user = find(this.users, user => {
            return user.id === budget.user_id;
          });

          return budget;
        });

        this._list = <Array<any>>list.reverse();

        this.resetList();
        this.store.reset();
      });
  }

  edit(item: any): void {
    this.router.navigate(['/budget/wizard/' + item.id]);
  }
  download(item) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'Content-Disposition': 'attachment',
        responseType: 'blob',
        Accept: 'application/pdf'
      })
    };

    this.http
      .get(environment.api_url + '/api/storage/pdf/' + item.id, httpOptions)
      .subscribe(
        res => {},
        err => {}
      );
  }

  public async downloadResource(item) {
    const file = await this.http
      .get<Blob>(environment.api_url + '/api/storage/pdf/' + item.id, {
        responseType: 'blob' as 'json'
      })
      .subscribe(
        blob => {
          const url = window.URL.createObjectURL(blob);

          const link = this.downloadZipLink.nativeElement;
          link.href = url;
          link.download = 'quote_' + item.id + '.pdf';
          link.click();

          window.URL.revokeObjectURL(url);
        },
        err => {
          console.log(err);
        }
      );
  }

  downloadUrl(item) {
    return environment.api_url + '/api/storage/pdf/' + item.id;
  }

  downloadFile(item) {
    return 'quote_' + item.id + '.pdf';
  }

  duplicate(item: any): void {
    this.store.reset();
    this.loading = true;
    this.http
      .get(environment.api_url + '/api/budgets/duplicate/' + item.id)
      .subscribe(
        res => {
          this.getData();
          this.message.success('Registro duplicado');
          this.loading = false;
        },
        err => {
          this.loading = false;
          this.message.error('Error duplicando registro');
        }
      );
  }
  filterListByDate(_pattern) {
    if (!_pattern) {
      return;
    }
  }

  filterListByPattern(_pattern) {
    if (!_pattern) {
      return;
    }

    const pattern = _pattern.toLowerCase();
    this.list = filter(this._list, (list: HistoricData) => {
      return (
        list.opportunity.includes(pattern) ||
        list.customer.toLowerCase().includes(pattern) ||
        list.created_at.toLowerCase().includes(pattern) ||
        list.address.toLowerCase().includes(pattern) ||
        list.budget.toLowerCase().includes(pattern)
      );
    });
    this.change.markForCheck();
  }

  resetList() {
    this.list = cloneDeep(this._list);
    this.change.markForCheck();
  }

  onChange(data) {}
}
