<ngx-charts-advanced-pie-chart
  [view]="view"
  [scheme]="colorScheme"
  [results]="graphData"
  [label]="label"
  [gradient]="gradient"
  [percentageFormatting]="euroFormatting"
  [valueFormatting]="euroFormatting"
  [nameFormatting]="euroFormatting"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
>
</ngx-charts-advanced-pie-chart>
