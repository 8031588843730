import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';

declare var google: any;

@Component({
  selector: 'solar-badget-pie-graph',
  templateUrl: './pie-graph.component.html',
  styleUrls: ['./pie-graph.component.scss']
})
export class PieGraphComponent implements OnInit, AfterViewInit {
  @Input() dataGoogle;
  @Input() label;

  @ViewChild('pieChart') pieChart: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  drawChart = () => {
    const data = google.visualization.arrayToDataTable(this.dataGoogle);

    const options = {
      title: this.label,
      colors: ['#E9483F', '#59C3E3', '#C1D100']
    };

    const chart = new google.visualization.PieChart(
      this.pieChart.nativeElement
    );

    chart.draw(data, options);
  };

  ngAfterViewInit() {
    google.charts.load('current', { packages: ['corechart'] });
    google.charts.setOnLoadCallback(this.drawChart);
  }
}
