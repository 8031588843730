import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'sintinta-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup;

  @ViewChild('tplTitle') tplTitle: TemplateRef<{}>;
  @ViewChild('tplContent') tplContent: TemplateRef<{}>;
  @ViewChild('tplFooter') tplFooter: TemplateRef<{}>;

  tplModal: NzModalRef;

  isVisible = false;

  checkingCretendials = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private message: NzMessageService,
    private router: Router
  ) {}

  login() {
    let { username, password } = this.validateForm.value;
    this.checkingCretendials = true;

    this.authService.login(username, password).subscribe(
      (res: { access_token: string }) => {
        this.checkingCretendials = false;
        if (res.access_token) {
          this.isVisible = false;
          setTimeout(() => {
            this.router.navigateByUrl('/budget/wizard');
          }, 500);
        } else {
          this.message.error('User credentials not valid');
        }
      },
      error => {
        this.checkingCretendials = false;
        if (error.status === 401) {
          this.message.error('User credentials not valid');
        } else {
          this.message.error(error.message);
        }
      }
    );
  }

  suffix() {
    if (
      !this.validateForm.value.username ||
      !this.validateForm.value.username.includes('@')
    ) {
      return '@eliveresolar.com';
    }
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });

    this.createTplModal();
  }

  createTplModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {

    setTimeout(() => {
      this.isVisible = false;
      this.checkingCretendials = false;
    }, 3000);
  }

  handleCancel(): void {
    this.isVisible = false;
  }
}
