import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  StoreService,
  FinancingOptions,
  BadgetFinancingData
} from '../../../shared/store-service.service';

import { find } from 'lodash';
import { ConfigService } from '../../../admin/config/config.service';
import { ConfigResponse } from '../../../admin/config/rates/rates.component';

import { get } from 'lodash';

export interface FinanancialResults {
  numberOfFees: NumberOfFees;
  initialEntry: NumberOfFees;
  cm: NumberOfFees;
  pvp: Pvp;
  cf: Pvp;
  if: Pvp;
  ptp: Pvp;
  ita: Pvp;
}

interface Pvp {
  value: number;
}

interface NumberOfFees {
  name: string;
  value: number;
}

@Component({
  selector: 'solar-badget-badget-installation',
  templateUrl: './badget-installation.component.html',
  styleUrls: ['./badget-installation.component.scss']
})
export class BadgetInstallationComponent implements OnInit {
  @Input() data;

  tplData;

  dynamicFinancialData = [];

  selectedDynamicFinancialData;

  financialData: FinanancialResults = {
    numberOfFees: { name: 'Número de cuotas', value: 0 },
    initialEntry: { name: 'Entrada inicial', value: 0 },
    cm: { name: 'Cuota mensual', value: 0 },
    pvp: { value: 0 },
    cf: { value: 0 },
    if: { value: 0 },
    ptp: { value: 0 },
    ita: { value: 0 }
  };

  private financingData: BadgetFinancingData = {
    investment: 0
  };

  financingOptions: FinancingOptions[] = [
    { label: 'No', value: 0 },
    { label: 'Sí', value: 1 }
  ];

  installationForm: FormGroup;

  euroSuffix = '€';
  pctgSuffix = '%';

  loading = true;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private configService: ConfigService,
    private change: ChangeDetectorRef
  ) {
    if (this.store.financialData) {
      this.financingData = this.store.financialData;
    } else {
      this.financingData = { investment: 0 };
    }
    this.tplData = this.store;

    if (this.store.installationType === 'residencial') {
      this.euroSuffix = '€ (IVA inc.)';
    }

    this.iniFinancialData();
  }

  formatterPercent = (value: number) => `${value} %`;

  private iniFinancialData() {
    // si ha cargado
    if (this.dynamicFinancialData.length) {
      // y está el mes definido
      if (this.financingData.months && this.financingData.months.month) {
        // busca el tipo seleccionado
        this.selectedDynamicFinancialData = find(
          this.dynamicFinancialData,
          option => option.month === this.financingData.months.month
        );
      }

      // si no hay nada seleccionado, el primero
      if (!this.selectedDynamicFinancialData) {
        this.selectedDynamicFinancialData = this.dynamicFinancialData[0];
      }

      if (this.selectedDynamicFinancialData) {
        this.updateFinancing(this.selectedDynamicFinancialData);
      }
    }
  }

  ngOnInit(): void {
    this.installationForm = this.fb.group({
      investment: [this.financingData.investment, [Validators.required]],
      entry: [this.financingData.entry],
      maintenance: [this.financingData.maintenance],
      openingCommission: [
        this.selectedDynamicFinancialData
          ? this.financingData.openingCommission
          : 0
      ],
      financialEntity: [this.financingData.financialEntity],
      financingRatio: [
        this.selectedDynamicFinancialData
          ? this.selectedDynamicFinancialData
          : 0
      ],
      tae: [
        this.selectedDynamicFinancialData
          ? this.selectedDynamicFinancialData.tae
          : 0
      ],
      tin: [
        this.selectedDynamicFinancialData
          ? this.selectedDynamicFinancialData.tin
          : 0
      ],
      months: [
        this.selectedDynamicFinancialData
          ? this.selectedDynamicFinancialData
          : 0
      ],
      // interests: [this.financingData.interests || 6],
      financing: [
        this.financingData.financing && this.financingData.financing.value === 1
          ? this.financingOptions[1]
          : this.financingOptions[0],
        [Validators.required]
      ]
    });

    this.configService.get().subscribe((datum: ConfigResponse[]) => {
      if (datum && datum.length) {
        const financial = find(datum, data => data.name === 'financial');

        if (financial) {
          this.dynamicFinancialData = JSON.parse(financial.config);
          this.iniFinancialData();
        }
      }
      this.loading = false;
      this.change.markForCheck();
    });

    this.installationForm.valueChanges.subscribe(
      (financingData: BadgetFinancingData) => {
        if (
          this.selectedDynamicFinancialData &&
          (financingData.financingRatio.coef !==
            this.selectedDynamicFinancialData.coef ||
            financingData.months.month !==
              this.selectedDynamicFinancialData.month)
        ) {
          if (
            financingData.financingRatio.coef !==
            this.selectedDynamicFinancialData.coef
          ) {
            this.selectedDynamicFinancialData = find(
              this.dynamicFinancialData,
              option => option.coef === financingData.financingRatio.coef
            );
          } else {
            this.selectedDynamicFinancialData = find(
              this.dynamicFinancialData,
              option => option.month === financingData.months.month
            );
          }

          if (this.selectedDynamicFinancialData) {
            this.updateFinancing(this.selectedDynamicFinancialData);
          }
        }

        // const obj = {
        //   investment: financingData.investment,
        //   entry: financingData.entry,
        //   maintenance: financingData.maintenance,
        //   openingCommission: financingData.openingCommission,
        //   financialEntity: financingData.financialEntity,
        //   financingRatio: financingData.financingRatio.coef,
        //   tae: financingData.tae,
        //   tin: financingData.tin,
        //   months: financingData.months.month,
        //   financing: financingData.financing
        // };

        this.store.setFinancialData(financingData);

        if (financingData.financing.value) {
          this.calculate();
        }
      }
    );

    if (get(this, 'financingData.financing.value', 0) === 1) {
      this.calculate();
    }
  }

  updateFinancing(data) {
    console.log(data);
    this.installationForm.patchValue({
      months: data,
      financingRatio: data,
      tae: data.tae,
      tin: data.tin
    });
  }

  calculate() {
    if (!this.selectedDynamicFinancialData) {
      return;
    }

    const form = this.installationForm.value;

    const data = this.financialData;

    // (Valor de la inversión)+(Mantenimiento anual)*(Número de cuotas)/12
    data.pvp.value =
      +form.investment + +form.maintenance * (+form.months.month / 12);

    // PVP * Comisión de apertura
    data.cf.value = data.pvp.value * form.openingCommission;

    // (PVP - Entrada + CF)  * Coeficiente de cuota
    data.cm.value =
      (data.pvp.value - form.entry + data.cf.value) *
      +this.selectedDynamicFinancialData.coef;

    // PVP - Entrada
    data.if.value = data.pvp.value - form.maintenance;

    // Entrada + CM * Número de cuotas
    data.ptp.value =
      +form.entry + data.cm.value * this.selectedDynamicFinancialData.month;

    // CM * Número de cuotas
    data.ita.value = data.cm.value * form.months.month;

    this.store.setFinancialResults(data);
  }
}
