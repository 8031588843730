<div style="margin-bottom:20px">
  <nz-alert
    *ngIf="!latLng2.lat"
    nzType="info"
    nzMessage="Pulsa en el mapa para seleccionar ubicación"
  ></nz-alert>
  <nz-alert
    *ngIf="latLng2.lat"
    nzType="success"
    [nzMessage]="msgLocation"
  ></nz-alert>
</div>

<div class="right">
  <div style="margin-bottom:30px">
    <nz-radio-group
      [(ngModel)]="seachByAddress"
      (ngModelChange)="toggleSearchByAddress()"
    >
      Buscar por:
      <label nz-radio nzValue="coor">Coordenadas</label>
      <label nz-radio nzValue="addr">Dirección</label>
      <button
        style="margin-left:10px; display: inline-block;"
        (click)="doSearch()"
        nz-button
        class="login-form-button"
        [nzType]="'primary'"
      >
        Buscar
      </button>
      <nz-spin
        style="display: inline-block;margin-left: 10px;"
        *ngIf="loadingMap"
        nzSimple
      ></nz-spin>
    </nz-radio-group>
  </div>
</div>

<form nz-form [formGroup]="locationForm">
  <div
    style="margin-top:15px;display: flex;flex-direction: column; justify-content: center"
  >
    <div *ngIf="seachByAddress === 'coor'" class="latlng-inputs">
      <nz-form-item style="flex:1;">
        <nz-form-label [nzSpan]="4">Latitud</nz-form-label>
        <nz-form-control [nzSpan]="15">
          <input
            type="text"
            nz-input
            nzAllowClear
            formControlName="lat"
            placeholder="Latitud"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="flex:1;">
        <nz-form-label [nzSpan]="4">Longitud</nz-form-label>
        <nz-form-control [nzSpan]="15">
          <input
            type="text"
            nz-input
            nzAllowClear
            formControlName="lng"
            placeholder="Longitud"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div *ngIf="seachByAddress !== 'coor'" class="address-inputs">
      <div class="latlng-inputs">
        <nz-form-item style="flex:1;">
          <nz-form-label [nzSpan]="6">Provincia</nz-form-label>
          <nz-form-control [nzSpan]="15">
            <input
              type="text"
              nz-input
              nzAllowClear
              formControlName="province"
              placeholder="Provincia"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item style="flex:1;">
          <nz-form-label [nzSpan]="6">Municipio</nz-form-label>
          <nz-form-control [nzSpan]="15">
            <input
              type="text"
              nz-input
              nzAllowClear
              formControlName="municipality"
              placeholder="Municipio"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item style="flex:1;">
          <nz-form-label [nzSpan]="6">Dirección</nz-form-label>
          <nz-form-control [nzSpan]="15">
            <input
              type="text"
              nz-input
              nzAllowClear
              formControlName="address"
              placeholder="Dirección"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>
</form>

<div class="map">
  <solar-badget-gmaps
    (centerChanges)="centerChanges($event)"
    (zoomChanges)="zoomChanges($event)"
    [zoom]="zoom"
    [latLng]="latLng"
    [geocode]="geocode"
    [geocodeResult]="geocodeResult"
  ></solar-badget-gmaps>
</div>
