import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { each, find, map, clone } from 'lodash';

interface ItemData {
  id: string;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'solar-badget-hourly-data-period',
  templateUrl: './consumption-hourly-data.component.html',
  styleUrls: ['./consumption-hourly-data.component.css']
})
export class ConsumptionHourlyDataComponent
  implements OnInit, OnChanges, OnDestroy {
  /**
   * Estaciones de los periodos horarios
   */
  @Input() data;

  /**
   * Estaciones de los periodos horarios
   */
  @Input() type;

  /**
   * Emite los datos de consumo Horario
   */
  @Output() hourlyData: EventEmitter<any> = new EventEmitter();

  periodsHourlySeasons = [{ name: 'Invierno' }, { name: 'Verano' }];

  validateForm: FormGroup;

  editCache: { [key: string]: { edit: boolean; data: ItemData } } = {};

  listOfData: any[] = [];

  installationType = 'residencial';

  hours = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];

  constructor(private fb: FormBuilder) {}

  ngOnDestroy() {}

  ngOnChanges(changes) {
    this.data = changes.data.currentValue;
    this.setData();
  }

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  total(data) {
    let total = 0;
    each(this.hours, hour => {
      total += +data[hour];
    });
    return +total.toFixed(0);
  }

  globalTotal() {
    let total = 0;
    each(this.listOfData, data => {
      total += +this.total(data);
    });
    return total.toFixed(2);
  }

  hourTotal(month) {
    let total = 0;
    each(this.listOfData, data => {
      const a = find(data, d => d.month === month);
      total += +data[month];
    });
    return total.toFixed(2);
  }

  cancelEdit(id: string): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: { ...this.listOfData[index] },
      edit: false
    };
  }

  saveWhileEdit(id: string): void {
    setTimeout(() => {
      Object.assign(this.listOfData[id], this.editCache[id].data);
      this.updateGraphData();
    }, 200);
  }

  saveEdit(id: string): void {
    Object.assign(this.listOfData[id], this.editCache[id].data);
    this.editCache[id].edit = false;
    this.updateGraphData();
  }

  updateEditCache(): void {
    this.listOfData.forEach((item, index) => {
      if (!this.editCache[index]) {
        this.editCache[index] = {
          edit: this.editCache[index] ? this.editCache[index].edit : false,
          data: { ...item }
        };
      }
    });
  }

  updateGraphData(): void {
    const graphData: any = [];

    this.listOfData.forEach((item, index) => {
      const series = map(item, (value, name) => {
        return {
          name,
          value: +value || 0
        };
      });

      graphData.push({
        name: this.periodsHourlySeasons[index],
        series
      });
    });

    this.hourlyData.emit({ data: this.listOfData, type: this.type });
  }

  getRandom() {
    return (Math.random() * 300).toFixed(2);
  }

  ngOnInit(): void {
    this.setData();
  }

  getData() {
    if (this.data) {
      return this.data;
    }

    return [];
  }

  private setData() {
    const consumptionHourlyData = this.getData();

    this.listOfData = clone(consumptionHourlyData);

    this.updateEditCache();
    this.updateGraphData();
  }
}
