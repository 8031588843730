import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'solar-badget-badget-graph-period',
  templateUrl: './badget-graph-period.component.html',
  styleUrls: ['./badget-graph-period.component.css']
})
export class BadgetGraphPeriodComponent implements OnInit {
  /**
   * Datos de las gráficas de consumo
   */
  @Input() graphConsumptionData;

  multi: any[];

  view: any[] = [1000, 400];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Meses';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Energía';
  animations: boolean = true;

  colorScheme = {
    domain: ['#59C3E3', '#E9483F', '#C1D100']
  };

  constructor() {}

  ngOnInit() {}

  onSelect(data) {}
}
