<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="sidebar-logo">
      <span>
        <img
          [ngClass]="{ logo: !isCollapsed, logo_collapsed: isCollapsed }"
          [src]="isCollapsed ? logo_img : logo"
          alt="logo"
          (click)="isCollapsed = !isCollapsed"
        />
      </span>
    </div>
    <ul
      *ngIf="user && user.name"
      nz-menu
      nzTheme="dark"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
      class="main-text"
    >
      <li nz-submenu nzOpen nzTitle="Informes" nzIcon="container">
        <ul>
          <li nz-menu-item nzMatchRouter class="main-text">
            <a routerLink="/budget/wizard"
              ><i nz-icon nzType="form" nzTheme="outline"></i> Asistente</a
            >
          </li>
          <li nzIcon="save" nz-menu-item nzMatchRouter>
            <a routerLink="/budget/history">
              <i nz-icon nzType="history" nzTheme="outline"></i>Histórico</a
            >
          </li>
        </ul>
      </li>
    </ul>

    <ul
      *ngIf="user && user.name"
      nz-menu
      nzTheme="dark"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
      class="main-text"
    >
      <li nzOpen nz-submenu nzTitle="Admin" nzIcon="control">
        <ul>
          <li nzIcon="user" nz-menu-item nzMatchRouter>
            <a routerLink="/users"
              ><i nz-icon nzType="team" nzTheme="outline"></i> Usuarios</a
            >
          </li>
          <li nzIcon="user" nz-menu-item nzMatchRouter>
            <a routerLink="/config"
              ><i nz-icon nzType="appstore" nzTheme="outline"></i> Config</a
            >
          </li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
