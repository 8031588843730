<div nz-row [nzGutter]="gutter">
  <form nz-form [formGroup]="searchForm" style="width: 100%;">
    <div nz-col nzSpan="10">
      <nz-form-item>
        <nz-input-group [nzSuffix]="suffixIconSearch">
          <input
            type="text"
            formControlName="search"
            nz-input
            placeholder="Búsqueda por texto"
          />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <i nz-icon nzType="search"></i>
        </ng-template>
      </nz-form-item>
    </div>
    <div
      nz-col
      nzSpan="2"
      style="
    top: 50px;
    position: absolute;
    right: 45px;"
    >
      <solar-badget-user-avatar></solar-badget-user-avatar>
    </div>
  </form>
</div>
<div style="overflow:scroll; border: 1px #eeeeee solid">
  <nz-table
    #basicTable
    [nzData]="list"
    [nzLoading]="loading"
    [nzScroll]="nzScroll"
    nzTitle="Listado de presupuestos"
  >
    <thead>
      <tr>
        <th
          *ngFor="let column of listOfColumn"
          [width]="column.width"
          [nzSortFn]="column.compare"
          [nzSortPriority]="column.priority"
        >
          {{ column.title }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let budget of basicTable.data">
        <td>{{ budget.customer }}</td>
        <td>{{ budget.opportunity }}</td>
        <td>
          {{ budget.created_at }}
        </td>
        <td>
          {{ budget.updated_at }}
        </td>
        <td>
          {{ budget.user.name }}
        </td>
        <td></td>
        <td>
          <button style="width: 140px;" (click)="edit(budget)" nz-button>
            <i nz-icon nzType="edit" nzTheme="outline"></i><span>Editar</span>
          </button>
          <button
            nz-popconfirm
            style="width: 140px;margin-top: 5px"
            nzPopconfirmTitle="¿Duplicar?"
            (nzOnConfirm)="duplicate(budget)"
            nz-button
          >
            <i nz-icon nzType="copy" nzTheme="outline"></i>
            <span>Duplicar</span>
          </button>
          <button
            *ngIf="budget.created_doc"
            style="width: 140px;margin-top: 5px"
            (click)="downloadResource(budget)"
            nz-button
          >
            <i nz-icon nzType="download" nzTheme="outline"></i>

            <span>Descargar</span>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
<a
  style="width: 140px;margin-top: 5px"
  class="download-zip-link"
  #downloadZipLink
>
</a>
