import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'solar-badget-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit, OnDestroy {
  name = '';
  color = '#EF7C3C';
  user;

  sub: Subscription;

  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    this.user = this.auth.user;
    this.name = this.user.name;

    this.sub = this.auth.userChanges().subscribe(user => {
      this.user = user;
      this.name = user.name;
    });
  }

  ngOnDestroy(): void {
    if(this.sub){
      this.sub.unsubscribe();
    }
  }

  logout() {
    this.auth.logout();
    window.location.reload();
  }
}
