import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'solar-badget-summary-graph-amortization',
  templateUrl: './summary-graph-amortization.component.html',
  styleUrls: ['./summary-graph-amortization.component.scss']
})
export class SummaryGraphAmortizationComponent implements OnInit {
  single: any[];
  multi: any[];

  @Input() data;

  graphData;

  view: any[] = [800, 650];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  yAxisLabel = '';

  colorUp = '#EF7C3C';
  colorDown = '#FCC358';

  colorScheme = {
    domain: []
  };

  constructor() {}

  onSelect(event) {
    console.log(event);
  }

  ngOnInit(): void {
    this.data.forEach(d => {
      if (d.value > 0) {
        this.colorScheme.domain.push(this.colorUp);
      } else {
        this.colorScheme.domain.push(this.colorDown);
      }
    });

    this.graphData = this.data;
  }
}
