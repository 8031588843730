<form *ngIf="!loading" nz-form [formGroup]="validateForm">
  <nz-form-item>
    <nz-form-label nzRequired [nzSpan]="7">P1</nz-form-label>
    <nz-form-control [nzSpan]="3" style="margin-right:10px;width: 100px;">
      <nz-input-group [nzSuffix]="suffixEuro">
        <input nz-input formControlName="p1" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired [nzSpan]="7">P2</nz-form-label>
    <nz-form-control [nzSpan]="3" style="margin-right:10px;width: 100px;">
      <nz-input-group [nzSuffix]="suffixEuro">
        <input nz-input formControlName="p2" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired [nzSpan]="7">P3</nz-form-label>
    <nz-form-control [nzSpan]="3" style="margin-right:10px;width: 100px;">
      <nz-input-group [nzSuffix]="suffixEuro">
        <input nz-input formControlName="p3" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired [nzSpan]="7">Precio de compensación</nz-form-label>
    <nz-form-control [nzSpan]="3" style="margin-right:10px;width: 100px;">
      <nz-input-group [nzSuffix]="suffixEuro">
        <input nz-input formControlName="salePrice" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <ng-template #prefixP1><span>P1:</span> </ng-template>
  <ng-template #prefixP2><span>P2: </span></ng-template>
  <ng-template #prefixP3><span>P3:</span> </ng-template>
  <ng-template #suffixEuro><span>€/kWh</span></ng-template>
  <ng-template #suffixPctg><span>%</span></ng-template>
  <button style="margin: 10px 0" nz-button nzType="primary" (click)="save()">
    Guardar
  </button>
</form>
<div style="height:200px">
  <nz-spin *ngIf="loading"></nz-spin>
</div>
