import { Component, OnInit, Input } from '@angular/core';
import { map } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'solar-badget-summary-graph-no-battery',
  templateUrl: './summary-graph-no-battery.component.html',
  styleUrls: ['./summary-graph-no-battery.component.scss']
})
export class SummaryGraphNoBatteryComponent implements OnInit {
  /**
   * Datos de las gráficas de consumo
   */
  @Input() data;

  multi: any[];

  view: any[] = [700, 400];

  months = [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic'
  ];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Meses';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Energía';
  animations: boolean = true;

  colorScheme = {
    domain: ['#59C3E3', '#E9483F', '#C1D100']
  };

  constructor(private http: HttpClient) {}

  ngOnInit() {

  }

  onSelect(data) {}
}
