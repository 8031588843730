<div>
  <nz-radio-group
    style="margin-bottom: 20px;display: flex"
    (ngModelChange)="changeType($event)"
    [(ngModel)]="typeValue"
  >
    <label nz-radio nzValue="1">Residencial</label>
    <label nz-radio nzValue="2">Industrial</label>
  </nz-radio-group>
  <nz-table
    #editRowTable
    nzBordered
    [nzData]="consumptionData"
    nzShowPagination="false"
    nzTotal="true"
    nzSize="small"
    style="width:950px"
  >
    <thead>
      <tr>
        <th></th>
        <th *ngFor="let month of months">{{ month }}</th>
        <th>Total</th>
        <th>Editar</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of editRowTable.data">
        <td>T{{ data.id + 1 }}</td>
        <td
          (click)="startEdit(data.id)"
          (keyup)="saveWhileEdit(data.id)"
          *ngFor="let month of months"
          style="padding: 0px 2px;text-align:center"
        >
          <ng-container *ngIf="!editCache[data.id].edit; else nameInputTpl">
            {{ data[month] }}
          </ng-container>
          <ng-template #nameInputTpl>
            <!-- DATA VALUE -->

            <div class="slider">
              <nz-slider
                [nzMin]="1"
                nzVertical
                [nzMax]="limit"
                (ngModelChange)="saveWhileEdit(data.id)"
                [(ngModel)]="editCache[data.id].data[month]"
              ></nz-slider>
            </div>

            <span *ngIf="!editByHand" (click)="editData()">{{
              editCache[data.id].data[month]
            }}</span>
            <span *ngIf="editByHand">
              <input
                style="margin: 15px 0px;width: 60px;"
                type="text"
                nz-input
                [(ngModel)]="editCache[data.id].data[month]"
              />
            </span>
          </ng-template>
        </td>
        <td>{{ total(data) }}</td>
        <td>
          <div class="editable-row-operations">
            <ng-container *ngIf="!editCache[data.id].edit; else saveTpl">
              <i
                style="cursor: pointer;"
                nzTooltipTitle="editar"
                nzTooltipPlacement="top"
                nz-tooltip
                (click)="startEdit(data.id)"
                nz-icon
                nzType="edit"
                nzTheme="outline"
              ></i>
            </ng-container>
            <ng-template #saveTpl>
              <i
                style="cursor: pointer;"
                nzTooltipTitle="Guardar"
                nzTooltipPlacement="top"
                nz-tooltip
                (click)="saveEdit(data.id)"
                nz-icon
                nzType="save"
                nzTheme="outline"
              ></i>
            </ng-template>
          </div>
        </td>
      </tr>
      <tr *ngIf="periodsData.length > 1" style="height: 32px;">
        <th>TOTAL</th>
        <th *ngFor="let month of months">{{ monthTotal(month) }}</th>
        <th>{{ globalTotal() }}</th>
        <th></th>
      </tr>
    </tbody>
  </nz-table>
</div>
