import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { each, find, clone } from 'lodash';
import {
  StoreService,
  BadgetData
} from '../../../shared/store-service.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

interface ItemData {
  id: string;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'solar-badget-badget-consumption-data-period',
  templateUrl: './badget-consumption-data-period.component.html',
  styleUrls: ['./badget-consumption-data-period.component.css']
})
export class BadgetConsumptionDataPeriodComponent
  implements OnInit, OnChanges, OnDestroy {
  /**
   * Datos de los periodos seleccionados
   * - Única
   * - Un periodo
   * - Dos periodos
   */
  @Input() periodsData;

  /**
   * Datos que llegan de otros
   */
  @Input() calculatedData;

  /**
   *
   */
  @Input() graphConsumptionData: any[] = [];

  /**
   *
   */
  @Output() graphData: EventEmitter<any> = new EventEmitter();

  /**
   *
   */
  @Output() editAction: EventEmitter<any> = new EventEmitter();

  /**
   *
   */
  @Output() consumptionPeriod: EventEmitter<any> = new EventEmitter();

  /**
   *
   */
  consumptionData: any[] = [];

  /**
   *
   */
  private defaultData = new Array(12).fill(1200 / 12);

  /**
   *
   */
  validateForm: FormGroup;

  /**
   *
   */
  editCache: { [key: string]: { edit: boolean; data: ItemData } } = {};

  /**
   * Tipo de
   */
  typeValue = '1';

  /**
   *
   */
  limit = 5000;

  /**
   *
   */
  months = [
    'ene',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec'
  ];

  resave; // tmp fix

  storeChangesSub: Subscription;

  data: BadgetData;

  yearlykWh = 0;

  increase = 0;

  editByHand = false;

  constructor(private store: StoreService) {}

  editData() {
    this.editByHand = true;
  }

  ngOnInit(): void {
    this.storeChangesSub = this.store.state$
      .pipe(debounceTime(1000))
      .subscribe((data: BadgetData) => {
        if (data.consumptionData && data.consumptionData.yearlykWh) {
          this.data = data;
          this.updatePeriodGraph();
        }
      });

    this.typeValue = this.store.installationType === 'industrial' ? '2' : '1';

    if (this.typeValue === '1') {
      this.limit = 5000;
    } else {
      this.limit = 20000;
    }
  }

  private updatePeriodGraph() {
    if (!this.data) {
      return;
    }

    const months = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (+this.data.consumptionData.yearlykWh === 0) {
      this.data.consumptionData.yearlykWh = 1200;
    }

    this.yearlykWh =
      +this.data.consumptionData.yearlykWh / this.periodsData.length;
    const defaultData = [];
    for (let i = 0; i < 12; i++) {
      if (this.data.consumptionData.increaseConsumption) {
        const total =
          (this.yearlykWh / 365) * months[i] +
          (this.data.consumptionData.increaseConsumption *
            ((this.yearlykWh / 365) * months[i])) /
            100;

        defaultData.push(total.toFixed(0));
      } else {
        defaultData.push(((this.yearlykWh / 365) * months[i]).toFixed(0));
      }
    }

    this.defaultData = defaultData;

    this.initializeData(this.periodsData, this.defaultData);
  }

  ngOnDestroy() {
    if (this.storeChangesSub) {
      this.storeChangesSub.unsubscribe();
    }
  }

  ngOnChanges(changes) {
    if (changes.periodsData && changes.periodsData.currentValue) {
      if (
        !changes.periodsData.previousValue ||
        changes.periodsData.currentValue.length !==
          changes.periodsData.previousValue.length
      ) {
        this.periodsData = changes.periodsData.currentValue;
        this.updatePeriodGraph();
        this.initializeData(this.periodsData);
      }
    }
  }

  //

  changeType(status) {
    if (status === '1') {
      this.limit = 5000;
    } else {
      this.limit = 20000;
    }
    this.typeValue = status === '2' ? 'industrial' : 'residencial';

    this.store.setInstallationType(this.typeValue);
  }

  startEdit(id: string, event): void {
    this.editAction.emit(true);
    this.editCache[id].edit = true;
  }

  total(data) {
    let total = 0;
    each(this.months, month => {
      total += +data[month];
    });
    return total.toFixed(0);
  }

  globalTotal() {
    let total = 0;
    each(this.consumptionData, data => {
      total += +this.total(data);
    });
    return total.toFixed(0);
  }

  monthTotal(month) {
    let total = 0;
    each(this.consumptionData, data => {
      // const a = find(data, d => d.month === month);
      total += +data[month];
    });
    return total.toFixed(2);
  }

  cancelEdit(id: string): void {
    const index = this.consumptionData.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: { ...this.consumptionData[index] },
      edit: false
    };
  }

  saveWhileEdit(id: string): void {
    setTimeout(() => {
      const index = this.consumptionData.findIndex(item => item.id === id);
      Object.assign(this.consumptionData[index], this.editCache[id].data);
      this.updateGraphData();
    }, 200);
  }

  saveEdit(id: string): void {
    const index = this.consumptionData.findIndex(item => item.id === id);
    Object.assign(this.consumptionData[index], this.editCache[id].data);
    this.editCache[id].edit = false;
    this.updateGraphData();
  }

  updateEditCache(): void {
    this.consumptionData.forEach(item => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  /**
   * Actualiza el gráfico con los cambios
   */
  updateGraphData(emit = true): void {
    const graphData: any = [];

    this.consumptionData.forEach(item => {
      each(this.months, month => {
        let period;

        period = find(graphData, data => {
          return data.name === month;
        });

        if (!period) {
          period = {
            name: month,
            series: []
          };
          graphData.push(period);
        }

        const name = `T${+item.id + 1}`;
        const dataPeriod = {
          name,
          value: +item[month]
        };

        period.series.push(dataPeriod);
      });
    });
    this.consumptionPeriod.emit(this.consumptionData);
    this.graphData.emit(graphData);
  }

  private initializeData(periodsData, data = null) {
    this.periodsData = periodsData;
    let graphConsumptionData;

    if (!data) {
      graphConsumptionData = this.store.get('consumptionPeriod', []);
    } else {
      this.consumptionData = [];
    }

    if (this.periodsData.length < this.consumptionData.length) {
      this.consumptionData = this.consumptionData.slice(
        0,
        this.periodsData.length
      );
    } else {
      for (
        let i = this.consumptionData.length;
        i < this.periodsData.length;
        i++
      ) {
        let consumptionData;

        if (graphConsumptionData && graphConsumptionData[i]) {
          consumptionData = graphConsumptionData[i];
        } else {
          consumptionData = {
            id: i
          };

          let cont = 0;
          for (const month of this.months) {
            consumptionData[month] = this.defaultData[cont++];
          }
        }

        this.consumptionData.push(consumptionData);
      }

      this.consumptionData = clone(this.consumptionData);
    }
    this.updateEditCache();
    this.updateGraphData();
  }
}
