<div class="main">
  <div class="form">
    <form nz-form [formGroup]="productionForm">
      <h4>VERTIENTES</h4>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Vertientes</nz-form-label>
        <nz-form-control [nzSpan]="6">
          <nz-select
            (ngModelChange)="slopeSelected($event)"
            [ngModel]="slopes"
            formControlName="slopes"
            nzPlaceHolder="Vertientes"
          >
            <nz-option
              *ngFor="let slope of slopesOptions"
              selected="true"
              [nzValue]="slope"
              [nzLabel]="slope.label"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <div>
        <solar-badget-badget-installation-slopes
          [num]="0"
          [slopeData]="slopesData[0]"
          (slopeChanges)="slopeChanges($event, 0)"
        ></solar-badget-badget-installation-slopes>
      </div>
      <div *ngIf="+slopes.value > 1">
        <solar-badget-badget-installation-slopes
          [num]="1"
          [slopeData]="slopesData[1]"
          (slopeChanges)="slopeChanges($event, 1)"
        ></solar-badget-badget-installation-slopes>
      </div>
      <div style="width:450px">
        <nz-divider [nzDashed]="true"></nz-divider>
      </div>
      <h4>BATERÍA</h4>
      <nz-form-item>
        <nz-form-label [nzSpan]="9">Batería</nz-form-label>
        <nz-form-control [nzSpan]="12">
          <nz-select
            style="width: 80px;display: table;"
            formControlName="battery"
            nzPlaceHolder="Choose"
          >
            <nz-option nzValue="false" nzLabel="No"></nz-option>
            <nz-option nzValue="true" nzLabel="Sí"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="showBatteryKWh">
        <nz-form-label [nzSpan]="9">Batería KWh</nz-form-label>
        <nz-form-control [nzSpan]="6">
          <input
            nz-input
            placeholder="Baterídata.customerData.opportunity,a KWh"
            formControlName="batterykWh"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-divider [nzDashed]="true"></nz-divider>
      <h4>PANELES</h4>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Nº de paneles</nz-form-label>
        <nz-form-control [nzSpan]="4">
          <nz-input-number
            formControlName="numberPanels"
            [nzMin]="1"
            [nzMax]="9999"
            [nzStep]="1"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9">Potencia del inversor</nz-form-label>
        <nz-form-control [nzSpan]="6" nzHasFeedback>
          <nz-input-group [nzSuffix]="suffixKW">
            <input
              nz-input
              formControlName="acPower"
              placeholder="Potencia del inversor"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  <div class="map">
    <solar-badget-badget-location></solar-badget-badget-location>
  </div>
</div>
<ng-template #suffixKW><span>KW</span> </ng-template>
