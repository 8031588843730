<div *ngIf="radiationResults && radiationResults.totalproductionKwYear">
  <nz-descriptions nzBordered>
    <nz-descriptions-item nzTitle="Oportunidad">{{
      badgetData.customerData.opportunity
    }}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Tipo de instalación">{{
      badgetData.installationType
    }}</nz-descriptions-item>
  </nz-descriptions>
  <div class="sep"></div>

  <nz-space-item nzDirection="vertical">
    <nz-descriptions nzLayout="vertical" nzBordered [nzColumn]="6">
      <nz-descriptions-item nzTitle="Producción kWh">{{
        radiationResults.totalproductionKwYear | number: '1.0-0'
      }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Demanda kWh">{{
        radiationResults.totalDemandaKwYear | number: '1.0-0'
      }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Importado"
        >{{ radiationResults.importadoDeLaRed | number: '1.0-0' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Autoconsumo kWh">{{
        radiationResults.ahorroAnual | number: '1.0-0'
      }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Batería">{{
        radiationResults.bateria | number: '1.0-0'
      }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Vertido"
        >{{ radiationResults.vertidoAnual | number: '1.0-0' }}
      </nz-descriptions-item>
    </nz-descriptions>
  </nz-space-item>
  <div class="sep"></div>
  <nz-space-item nzDirection="vertical">
    <nz-descriptions nzBordered [nzColumn]="3" nzLayout="vertical">
      <nz-descriptions-item nzTitle="Producido T1">
        Precio: {{ badgetData.consumptionData.p1 }} <br />
        {{ radiationResults.producidoKWh.p1 | number: '1.0-0' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Producido T2">
        Precio: {{ badgetData.consumptionData.p2 }} <br />
        {{ radiationResults.producidoKWh.p2 | number: '1.0-0' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Producido T3">
        Precio: {{ badgetData.consumptionData.p3 }} <br />
        {{ radiationResults.producidoKWh.p3 | number: '1.0-0' }}
      </nz-descriptions-item>
    </nz-descriptions>
  </nz-space-item>
  <div class="sep"></div>
  <!-- DEMANDA -->
  <nz-space-item nzDirection="vertical">
    <nz-descriptions nzBordered [nzColumn]="3" nzLayout="vertical">
      <nz-descriptions-item nzTitle="Demanda T1">
        Precio: {{ badgetData.consumptionData.p1 }} <br />
        {{ radiationResults.demandaKWh.p1 | number: '1.0-0' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Demanda T2">
        Precio: {{ badgetData.consumptionData.p2 }} <br />
        {{ radiationResults.demandaKWh.p2 | number: '1.0-0' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Demanda T3">
        Precio: {{ badgetData.consumptionData.p3 }} <br />
        {{ radiationResults.demandaKWh.p3 | number: '1.0-0' }}
      </nz-descriptions-item>
    </nz-descriptions>
  </nz-space-item>
  <div class="sep"></div>
  <!-- IMPORTADO -->
  <nz-space-item nzDirection="vertical">
    <nz-descriptions nzBordered [nzColumn]="3" nzLayout="vertical">
      <nz-descriptions-item nzTitle="Importado T1">
        Precio: {{ badgetData.consumptionData.p1 }} <br />
        {{ radiationResults.importadoKWh.p1 | number: '1.0-0' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Importado T2">
        Precio: {{ badgetData.consumptionData.p2 }} <br />
        {{ radiationResults.importadoKWh.p2 | number: '1.0-0' }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Importado T3">
        Precio: {{ badgetData.consumptionData.p3 }} <br />
        {{ radiationResults.importadoKWh.p3 | number: '1.0-0' }}
      </nz-descriptions-item>
    </nz-descriptions>
  </nz-space-item>
  <div class="sep"></div>
  <nz-space-item nzDirection="vertical">
    <nz-descriptions nzBordered [nzColumn]="4" nzLayout="vertical">
      <nz-descriptions-item nzTitle="Gasto Pasado €">
        demandaKWhTarifa * tarifa correspondiente <br />
        {{ pvp.gastoPasado | number: '1.0-0' }}
        <span *ngIf="clienteFinal()"> IVA inc.</span>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Auto consumido €">
        {{ pvp.autoconsumido | number: '1.0-0' }}
        <span *ngIf="clienteFinal()"> IVA inc.</span>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Importado €">
        {{ pvp.importado | number: '1.0-0' }}
        <span *ngIf="clienteFinal()"> IVA inc.</span>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Vertido €">
        {{ pvp.vertidoEuros | number: '1.0-0' }}
        <span *ngIf="clienteFinal()"> IVA inc.</span>
      </nz-descriptions-item>
    </nz-descriptions>
  </nz-space-item>
  <div class="sep"></div>
  <nz-space-item nzDirection="vertical">
    <nz-descriptions nzBordered [nzColumn]="5" nzLayout="vertical">
      <nz-descriptions-item nzTitle="Gastabas €">
        gasto pasado <br />
        {{ pvp.gastoPasado | number: '1.0-0' }}
        <span *ngIf="clienteFinal()">/ IVA inc.</span>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Gastarás €">
        gasto pasado - (autoconsumido + vertido) <br />
        {{ pvp.gastoFuturo | number: '1.0-0' }}
        <span *ngIf="clienteFinal()">/ IVA inc.</span>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Ahorrarás €">
        autoconsumido ({{ pvp.autoconsumido | number: '1.0-0' }}) + vertido ({{
          pvp.compensado
        }}) <br />
        {{ pvp.autoconsumido + pvp.compensado | number: '1.0-0' }}
        <span *ngIf="clienteFinal()"> IVA inc.</span>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Rentabilidad anual %" *ngIf="badgetData">
        ((autoconsumido
        {{ pvp.autoconsumido | number: '1.0-0' }}
        + vertido {{ pvp.compensado }}) * 100) / inversion
        {{ pvp.inversion | number: '1.0-0' }}
        <br />
        {{
          ((pvp.autoconsumido + pvp.compensado) * 100) / pvp.inversion
            | number: '1.0-0'
        }}
      </nz-descriptions-item>
      <nz-descriptions-item
        nzTitle="Recuperación de la inversión"
        *ngIf="badgetData"
      >
        inversion {{ pvp.inversion }} / (autoconsumido)
        {{ pvp.autoconsumido | number: '1.0-0' }} + vertido
        {{ pvp.compensado | number: '1.0-0' }})
        <br />
        {{
          pvp.inversion / pvp.autoconsumido + pvp.compensado | number: '1.0-0'
        }}
      </nz-descriptions-item>
    </nz-descriptions>
  </nz-space-item>
</div>
