<div *ngIf="!loading">
  <div style="margin: 0 50px">
    <h4>
      Residencial
    </h4>

    <solar-badget-hourly-data-period
      [data]="residencial"
      type="residencial"
      (hourlyData)="updateData($event)"
    ></solar-badget-hourly-data-period>

    <h4>
      Industrial
    </h4>

    <solar-badget-hourly-data-period
      [data]="industrial"
      type="industrial"
      (hourlyData)="updateData($event)"
    ></solar-badget-hourly-data-period>
  </div>
  <button style="margin: 40px 0" nz-button nzType="primary" (click)="save()">
    Guardar
  </button>
</div>
<div style="height:200px">
  <nz-spin *ngIf="loading"></nz-spin>
</div>
