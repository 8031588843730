<form
  style="margin: 20px 0px"
  nz-form
  [formGroup]="financialForm"
  [nzLayout]="'inline'"
>
  <nz-form-item>
    <nz-form-label nzRequired [nzSpan]="7">Meses</nz-form-label>
    <nz-form-control [nzSpan]="6" style="">
      <nz-input-group>
        <input nz-input formControlName="month" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired [nzSpan]="8">Coeficiente</nz-form-label>
    <nz-form-control [nzSpan]="8" style="">
      <nz-input-group>
        <input nz-input formControlName="coef" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired [nzSpan]="7">TAE</nz-form-label>
    <nz-form-control [nzSpan]="6" style="">
      <nz-input-group>
        <input nz-input formControlName="tae" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired [nzSpan]="7">TIN</nz-form-label>
    <nz-form-control [nzSpan]="6" style="">
      <nz-input-group>
        <input nz-input formControlName="tin" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <button *ngIf="!removeItem" nz-button (click)="activateRemove()">
    <i nz-icon nzType="delete" nzTheme="outline" style="color:red"></i> Eliminar
  </button>
  <button *ngIf="removeItem" nz-button (click)="remove()">
    <i nz-icon nzType="delete" nzTheme="outline" style="color:red"></i>
    ¿Eliminar?
  </button>
</form>
