import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users/users.component';
import { UIModule } from '../app.ui';
import { ReactiveFormsModule } from '@angular/forms';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';

@NgModule({
  declarations: [UsersComponent, UserAvatarComponent],
  imports: [CommonModule, UIModule, ReactiveFormsModule],
  exports: [UserAvatarComponent]
})
export class UsersModule {}
