<form nz-form [formGroup]="consumptionForm">
  <nz-form-item>
    <nz-form-label nzRequired [nzSpan]="7">Potencia contratada</nz-form-label>
    <nz-form-control [nzSpan]="3" style="margin-right:10px;width: 150px;">
      <nz-input-group nzSuffix="kW">
        <input
          nz-input
          nzAllowClear
          formControlName="hiredPotency"
          placeholder="Potencia contratada"
      /></nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="7">kWh anuales</nz-form-label>
    <nz-form-control [nzSpan]="3" style="margin-right:10px;width: 150px;">
      <nz-input-group nzSuffix="kWh">
        <input nz-input formControlName="yearlykWh" placeholder="kWh anuales" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="consumptionForm.value.yearlykWh">
    <nz-form-label [nzSpan]="7">¿Se prevee aumento del consumo?</nz-form-label>
    <nz-form-control [nzSpan]="2" style="margin-right:10px;width: 100px;">
      <nz-input-number
        style="width:150px"
        formControlName="increaseConsumption"
        [nzMin]="0"
        [nzStep]="100"
        [nzStep]="0.5"
        [nzFormatter]="formatterPercent"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired [nzSpan]="7">Tipo de tarifa</nz-form-label>
    <nz-select
      style="width: 150px;display: flow-root"
      formControlName="rateType"
      nzPlaceHolder="Tipo de tarifa"
    >
      <nz-option nzValue="1" nzLabel="Un periodo"></nz-option>
      <nz-option nzValue="2" nzLabel="Dos periodos"></nz-option>
      <nz-option nzValue="3" nzLabel="Tres periodos"></nz-option>
    </nz-select>
  </nz-form-item>
  <div
    style="display: inline-block;"
    *ngIf="showMonthyConsumptionPeriods && periodsData.length"
  >
    <!-- DATOS DE CONSUMO MENSUAL -->
    <solar-badget-badget-consumption-data-period
      [periodsData]="periodsData"
      (graphData)="setgraphConsumptionData($event)"
      (consumptionPeriod)="setgraphConsumptionPeriodData($event)"
      (editAction)="cleanAnuals()"
    ></solar-badget-badget-consumption-data-period>
  </div>
  <div
    *ngIf="showMonthyConsumptionPeriods && periodsData.length"
    style="display: inline-block;
    margin: 30px auto;
    min-width: 100%;"
  >
    <!-- GRÁFICA DE CONSUMOS MENSUALES -->
    <solar-badget-badget-graph-period
      style="display: inline-block;"
      [graphConsumptionData]="graphConsumptionData"
    ></solar-badget-badget-graph-period>
  </div>

  <div style="display: inline-block;">
    <!-- DATOS DE CONSUMO HORARIO -->
    <solar-badget-consumption-hourly-data-period
      [periodsHourlySeasons]="periodsHourlySeasons"
      (hourlyData)="setGraphHourlyData($event)"
    ></solar-badget-consumption-hourly-data-period>
  </div>

  <div
    style="display: inline-block;
    margin: 30px auto;
    min-width: 100%;"
  >
    <!-- GRÁFICA DE CONSUMOS POR HORA -->
    <solar-badget-consumption-hourly-graph
      style="display: inline-block;"
      [data]="graphHourlyData"
    ></solar-badget-consumption-hourly-graph>
  </div>

  <span *ngIf="showMonthyConsumptionPeriods && periodsData.length">
    <nz-form-item>
      <nz-form-label nzRequired [nzSpan]="7">P1</nz-form-label>
      <nz-form-control [nzSpan]="2" style="margin-right:10px;width: 100px;">
        <nz-input-group [nzSuffix]="suffixEuro">
          <input nz-input formControlName="p1" />
        </nz-input-group>
      </nz-form-control>
      <span class="sin-iva"> (sin IVA)</span>
    </nz-form-item>
  </span>
  <span *ngIf="showMonthyConsumptionPeriods && periodsData.length > 1">
    <nz-form-item *ngIf="showMonthyConsumptionPeriods && periodsData.length">
      <nz-form-label nzRequired [nzSpan]="7">P2</nz-form-label>
      <nz-form-control [nzSpan]="2" style="margin-right:10px;width: 100px;">
        <nz-input-group [nzSuffix]="suffixEuro">
          <input nz-input formControlName="p2" />
        </nz-input-group> </nz-form-control
      ><span class="sin-iva"> (sin IVA)</span>
    </nz-form-item>
  </span>
  <span *ngIf="showMonthyConsumptionPeriods && periodsData.length > 2">
    <nz-form-item>
      <nz-form-label nzRequired [nzSpan]="7">P3</nz-form-label>
      <nz-form-control [nzSpan]="2" style="margin-right:10px;width: 100px;">
        <nz-input-group [nzSuffix]="suffixEuro">
          <input nz-input formControlName="p3" />
        </nz-input-group>
      </nz-form-control>
      <span class="sin-iva"> (sin IVA)</span>
    </nz-form-item>
  </span>
  <span>
    <nz-form-item>
      <nz-form-label nzRequired [nzSpan]="7"
        >Precio de compensación</nz-form-label
      >
      <nz-form-control [nzSpan]="2" style="margin-right:10px;width: 100px;">
        <nz-input-group [nzSuffix]="suffixEuro">
          <input nz-input formControlName="salePrice" />
        </nz-input-group>
      </nz-form-control>
      <span class="sin-iva"> (sin IVA)</span>
    </nz-form-item>
  </span>
  <ng-template #prefixP1><span>P1:</span> </ng-template>
  <ng-template #prefixP2><span>P2: </span></ng-template>
  <ng-template #prefixP3><span>P3:</span> </ng-template>
  <ng-template #suffixEuro><span>€/kWh</span></ng-template>
  <ng-template #suffixPctg><span>%</span></ng-template>
</form>
