<form nz-form [formGroup]="slopeForm">
  <h4>VERTIENTE {{ num + 1 }}</h4>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired
      >Potencia pico instalada</nz-form-label
    >
    <nz-form-control [nzSpan]="6">
      <nz-input-group nzSuffix="kW">
        <input
          type="text"
          nz-input
          formControlName="installed_peak_power"
          placeholder="Potencia"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Inclinación</nz-form-label>
    <nz-form-control [nzSpan]="4">
      <nz-input-group nzSuffix="°">
        <input
          type="text"
          nz-input
          formControlName="inclination"
          placeholder="Inclinación"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Azimut</nz-form-label>
    <nz-form-control [nzSpan]="4">
      <nz-input-group nzSuffix="°">
        <input
          type="text"
          nz-input
          formControlName="azimut"
          placeholder="Orientación respecto al sur"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
</form>
