import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';

export interface ConfigResponse {
  id: number;
  name: string;
  config: string;
}

import { find } from 'lodash';
import { ConfigService } from '../config.service';

@Component({
  selector: 'solar-badget-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit {
  validateForm: FormGroup;

  rates = {
    name: 'rates',
    id: null,
    config: ''
  };

  loading = true;

  save() {
    const config = this.validateForm.value;
    this.rates.config = JSON.stringify(config);
    if (this.rates.id) {
      this.configService.update(this.rates).subscribe((res: any) => {
        this.message.success('Tarifas actualizadas');
      });
    } else {
      const obj = {
        name: this.rates.name,
        config: this.rates.config
      };

      this.configService.create(obj).subscribe((res: any) => {
        if (res.config) {
          this.rates.id = res.config.id;
          this.message.success('Tarifas creadas');
        }
      });
    }
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  constructor(
    private fb: FormBuilder,
    private configService: ConfigService,
    private message: NzMessageService
  ) {
    this.validateForm = this.fb.group({
      p1: ['', [Validators.required]],
      p2: ['', [Validators.email]],
      p3: ['', [Validators.required]],
      salePrice: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.configService.get().subscribe((rates: ConfigResponse[]) => {
      if (rates && rates.length) {
        const rate = find(rates, rate => rate.name === this.rates.name);
        if (rate) {
          this.rates.id = rate.id;
          this.validateForm.patchValue(JSON.parse(rate.config));
        }
      }
      this.loading = false;
    });
  }
}
