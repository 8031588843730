<form style="text-align: left;" nz-form [formGroup]="validateForm">
  <div style="display: inline-block;width: 45%">
    <nz-form-item>
      <nz-form-label [nzSpan]="7" nzRequired>Nombre</nz-form-label>
      <nz-form-control [nzSpan]="12">
        <input
          nz-input
          formControlName="name"
          placeholder="nombre del cliente"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="7" nzRequired>Apellidos</nz-form-label>
      <nz-form-control [nzSpan]="12">
        <input
          nz-input
          formControlName="surname"
          placeholder="Apellidos del cliente"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="7" nzRequired>Dirección</nz-form-label>
      <nz-form-control
        [nzSpan]="12"
        nzHasFeedback
        [nzErrorTip]="addressErrorTpl"
      >
        <input nz-input formControlName="address" placeholder="Dirección" />
        <ng-template #addressErrorTpl let-control>
          <ng-container *ngIf="control.hasError('adress')">
            No es una dirección válida
          </ng-container>
          <ng-container *ngIf="control.hasError('required')">
            Introduce dirección
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="7">Contacto</nz-form-label>
      <nz-form-control [nzSpan]="12" nzHasFeedback>
        <input nz-input formControlName="contact" placeholder="Contacto" />
      </nz-form-control>
    </nz-form-item>
  </div>

  <!-- <div>
    <nz-divider nzType="vertical"></nz-divider>
  </div> -->

  <div style="display: inline-block;width: 45%; vertical-align: top;">
    <nz-form-item>
      <nz-form-label [nzSpan]="7" nzRequired>Oportunidad</nz-form-label>
      <nz-form-control [nzSpan]="10" nzHasFeedback>
        <nz-input-number
          style="width:200px"
          formControlName="opportunity"
          [nzMin]="0"
          [nzStep]="100"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="7">Título</nz-form-label>
      <nz-form-control [nzSpan]="12" nzHasFeedback>
        <input nz-input formControlName="title" placeholder="Título" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="7">Descripción</nz-form-label>
      <nz-form-control [nzSpan]="12">
        <textarea
          formControlName="comment"
          nz-input
          rows="2"
          placeholder="Descripción"
        ></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>

<!-- <nz-modal
[(nzVisible)]="isVisible"
nzTitle="Modal Title"
(nzOnCancel)="handleCancel()"
(nzOnOk)="handleOk()"
>
<p>Modal Content</p>
</nz-modal> -->
