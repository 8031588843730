import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigComponent } from './config/config.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UIModule } from '../app.ui';
import { RatesComponent } from './config/rates/rates.component';
import { ConsumptionHourlyComponent } from './config/consumption-hourly/consumption-hourly.component';
import { SharedModule } from '../shared/shared.module';
import { FinancialComponent } from './config/financial/financial.component';
import { FinancialOptionComponent } from './config/financial-option/financial-option.component';

@NgModule({
  declarations: [ConfigComponent, RatesComponent, ConsumptionHourlyComponent, FinancialComponent, FinancialOptionComponent],
  imports: [CommonModule, UIModule, ReactiveFormsModule, SharedModule]
})
export class AdminModule {}
