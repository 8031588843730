import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { NzMessageService, UploadChangeParam } from 'ng-zorro-antd';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
  BadgetData,
  StoreService
} from '../../../shared/store-service.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { map, each, get } from 'lodash';
import { QuoteSummary } from './quote-results.type';

export interface PVPResults {
  compensacionSimple: number;
  ahorroTotal: number;
  rentabilidadAnual: number;
  recuperacionDeInversion: number;
  precioVenta: number;
  inversion: number;
  inversionTotal?: number;
  autoconsumido: number;
  compensado: number;
  gastoPasado: number;
  gastoFuturo: number;
  importado: number;
  vertidoEuros: number;
}

interface RadiationResuls {
  vertidoAnual: number;
  ahorroAnual: number;
  importadoDeLaRed: number;
  solarTotal: number;
  consumoTotal: number;
  installed_peak_power: number;
  financialData: any;
  financialPrevisions: any;
  res: Res;
}

interface Res {
  '10': _10;
  '11': _10;
  '12': _10;
  '01': _10;
  '02': _10;
  '03': _10;
  '04': _10;
  '05': _10;
  '06': _10;
  '07': _10;
  '08': _10;
  '09': _10;
}

interface _10 {
  radiation: number;
  demand: number;
}

const pageWith = 1600;
const pageHeight = 2255;

@Component({
  selector: 'solar-badget-badget-results',
  templateUrl: './badget-results.component.html',
  styleUrls: ['./badget-results.component.scss']
})
export class BadgetResultsComponent implements OnInit, OnDestroy {
  /**
   *
   */
  summaryEditable = false;
  numOfPanels = false;
  installedPower = false;
  nominalPower = false;
  cleanEnergyShow = false;
  co2Show = false;
  treesShow = false;

  showUploads = true;

  apiUploadUrl;

  ahorroAutoconsumo = 0;

  ahorroAnual = 0;
  inversion = 0;
  ahorroEntregadoRed = 0;

  compensacionSimplificada = {
    gastabas: {
      euros: 0,
      pctg: 100
    },
    gastaras: {
      euros: 0,
      pctg: 0
    },
    ahorraras: {
      euros: 0,
      pctg: 0
    }
  };

  rentabilidadAnual = 0;
  recuperacionDeInversion;

  // user edition
  comercialName = null;
  comercialPhone = null;
  comercialMail = null;
  comercialMessage = null;

  textWithBattery =
    'Se trata de una instalación fotovoltaica para autoconsumo (con batería en su caso) conectado a red y compensación simplificada de excedentes';

  textWithoutBattery =
    'Se trata de una instalación fotovoltaica para autoconsumo conectado a red y compensación simplificada de excedentes';

  images = {
    d3: {
      url: null
    }
  };

  months = [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic'
  ];

  panels = 1;

  nominal = 0;

  power = 0;

  battery = 0;

  cleanEnergy = 0;

  salecleanEnergy = 0;

  co2 = 0;

  trees = 0;

  cars = 0;

  listOfData = [
    {
      key: '1',
      name: 'Año',
      p1: '0',
      p2: '1',
      p3: '2',
      p4: '3',
      p5: '4',
      p6: '5',
      p7: '6',
      p8: '7',
      p9: '8',
      p10: '9',
      p11: '10'
    },
    {
      key: '1',
      name: 'Ahorros',
      p1: (Math.random() + 400).toFixed(0),
      p2: (Math.random() + 400).toFixed(0),
      p3: (Math.random() + 400).toFixed(0),
      p4: (Math.random() + 400).toFixed(0),
      p5: (Math.random() + 400).toFixed(0),
      p6: (Math.random() + 400).toFixed(0),
      p7: (Math.random() + 400).toFixed(0),
      p8: (Math.random() + 400).toFixed(0),
      p9: (Math.random() + 400).toFixed(0),
      p10: (Math.random() + 400).toFixed(0),
      p11: (Math.random() + 400).toFixed(0)
    },
    {
      key: '1',
      name: 'Beneficio acumulado',
      p1: (Math.random() - 800).toFixed(0),
      p2: (Math.random() - 800).toFixed(0),
      p3: (Math.random() - 800).toFixed(0),
      p4: (Math.random() - 800).toFixed(0),
      p5: (Math.random() - 800).toFixed(0),
      p6: (Math.random() - 800).toFixed(0),
      p7: (Math.random() - 800).toFixed(0),
      p8: (Math.random() - 800).toFixed(0),
      p9: (Math.random() - 800).toFixed(0),
      p10: (Math.random() - 800).toFixed(0),
      p11: (Math.random() - 800).toFixed(0)
    }
  ];

  social = {
    trees: 0.0192,
    co2: 0.385,
    cars: 5
  };

  graphdata;

  graphdataGoogle;

  radiationData;

  @ViewChild('reportContent') reportContent: ElementRef;

  data = <BadgetData>{};

  sub: Subscription;

  calculating = false;

  dataCalculated;

  radiationResults: QuoteSummary;

  ahoroAnual = 0;

  precioVenta = 0;

  pvp: PVPResults = {
    compensacionSimple: 0,
    ahorroTotal: 0,
    rentabilidadAnual: 0,
    recuperacionDeInversion: 0,
    precioVenta: 0,
    inversion: 0,
    autoconsumido: 0,
    compensado: 0,
    gastoPasado: 0,
    gastoFuturo: 0,
    vertidoEuros: 0,
    importado: 0
  };

  financialData;

  financialPrevisions;

  amortizacionData;

  installed_peak_power;

  financialGraph;

  financialGraphLabel = 'Balance energético anual en €';

  user;

  ivaIncludo = 'IVA incluido.';

  heightInfo = window.screen.height - 400;

  tabNumber = 0;

  showImage = true;

  constructor(
    private msg: NzMessageService,
    private store: StoreService,
    private http: HttpClient,
    private change: ChangeDetectorRef,
    private location: Location,
    private message: NzMessageService
  ) {
    this.user = JSON.parse(localStorage.getItem('user'));

    this.apiUploadUrl =
      environment.api_url + '/api/storage/file-budget/' + this.store.budgetId;

    const d3Url =
      environment.api_url + '/uploads/op_d3_' + this.store.budgetId + '.jpg';

    this.updateD3Image(d3Url);

    this.data = this.store.getData();
  }

  tabIndex(tab) {
    this.tabNumber = tab;
  }

  private updateD3Image(d3Url: string) {
    this.images.d3 = {
      url: 'https://app.eliveresolar.com/assets/imgs/empty.png'
    };

    const http = new XMLHttpRequest();

    http.open('HEAD', d3Url, false);
    http.send();

    if (http.status != 404) {
      this.images.d3 = {
        url: d3Url + '?' + new Date().getTime()
      };
    }
    // this.http.get(d3Url).subscribe(
    //   res => {
    //     this.images.d3 = {
    //       url: d3Url + '?' + new Date().getTime()
    //     };
    //     console.log('imágenes');

    //     console.log(this.images);
    //   },
    //   err => {
    //     if (err.status === 200) {
    //       this.images.d3 = {
    //         url: d3Url
    //       };
    //     }
    //   }
    // );
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  checkUrl(url) {
    this.sub = this.http.get(url).subscribe(
      res => {
        this.images.d3 = url;
      },
      error => {
        console.log(error);
      }
    );
  }

  calculate() {
    this.calculating = true;
    const url =
      environment.api_url +
      '/api/budget/radiation/calculate/' +
      this.store.customerData.opportunity;

    this.http.get(url).subscribe(
      (data: QuoteSummary) => {
        const radiationResults = data[0];

        // radiationResults.financialData = radiationResults2;
        this.radiationResults = radiationResults;

        if (!this.radiationResults.solarTotal) {
          return;
        }

        this.calculating = false;

        // Datos sociales
        this.trees = this.social.trees * this.radiationResults.solarTotal;
        this.co2 = this.social.co2 * this.radiationResults.solarTotal;
        this.cars = this.social.cars * this.radiationResults.solarTotal;

        this.installed_peak_power = this.radiationResults.installed_peak_power;

        this.radiationData = data;

        this.graphdata = this.convert(data);

        this.graphdataGoogle = this.convertToGoogleStacked(data);

        this.precioVenta = this.store.consumptionData.salePrice;

        this.financialData = this.radiationResults.financialData;

        // datos financieros
        this.financialPrevisions = this.radiationResults.financialPrevisions;

        // PVP
        this._calculatePVP();

        this.change.detectChanges();
      },
      error => {
        this.message.error(
          `Ha habido un error al recuperar la radiación ${JSON.stringify(
            error
          )}`
        );
      }
    );
  }

  private _calculatePVP() {
    this.pvp.inversion = this.getInvesment();

    this.pvp.inversionTotal = this.getInvesmentTotal();

    this.pvp.precioVenta = this.precioFinal(this.precioVenta);

    this.pvp.importado = this.precioFinal(this.financialData.importado);

    this.pvp.vertidoEuros = this.precioFinal(this.financialData.vertidoEuros);

    // compensación
    this.pvp.compensacionSimple =
      this.radiationResults.vertidoAnual * this.pvp.precioVenta;

    this.pvp.ahorroTotal = this.precioFinal(
      this.financialData.autoconsumido + this.financialData.compensado
    );

    this.pvp.autoconsumido = this.precioFinal(this.financialData.autoconsumido);

    this.pvp.compensado = this.precioFinal(this.financialData.compensado);

    this.pvp.rentabilidadAnual =
      (this.pvp.ahorroTotal * 100) / this.pvp.inversionTotal;

    this.pvp.recuperacionDeInversion =
      this.pvp.inversionTotal / this.pvp.ahorroTotal;

    this.pvp.gastoPasado = this.precioFinal(
      this.financialPrevisions.gastoPasado
    );

    this.compensacionSimplificada.gastabas.pctg = 100;

    this.pvp.gastoFuturo = this.pvp.gastoPasado - this.pvp.ahorroTotal;

    this.compensacionSimplificada.gastaras.pctg =
      (this.pvp.gastoFuturo * 100) / this.pvp.gastoPasado;

    this.compensacionSimplificada.ahorraras.pctg =
      100 - this.compensacionSimplificada.gastaras.pctg;

    if (this.pvp.ahorroTotal < 0) {
      this.pvp.ahorroTotal = 0;
    }

    if (this.pvp.gastoFuturo < 0) {
      this.pvp.gastoFuturo = 0;
    }
  }

  getInvesment() {
    let inves = 0;
    console.log('financialResults');
    console.log(this.data);
    if (get(this, 'data.financialResults.pvp.value')) {
      inves = this.data.financialResults.pvp.value;
    } else if (get(this, 'data.financingData.investment')) {
      inves = this.data.financingData.investment;
    }

    //  inves = this.precioFinal(inves);F

    return this.data.financingData.investment;
  }

  getInvesmentTotal() {
    let inves = 0;

    if (get(this, 'data.financialResults.pvp.value')) {
      inves = this.data.financialResults.pvp.value;
    } else if (get(this, 'data.financingData.investment')) {
      inves = this.data.financingData.investment;
    }

    //  inves = this.precioFinal(inves);F

    return inves;
  }

  precioFinal(precio) {
    if (this.store.installationType === 'residencial') {
      return precio * 1.21;
    }

    if (precio < 0) {
      precio = 0;
    }

    return precio;
  }

  onBack() {
    this.location.back();
  }

  convert(dataToConvert) {
    let data = map(dataToConvert[0].res, (val, prop) => {
      const values = Object.values(val);
      const keys = Object.keys(val);

      return {
        name: this.months[+prop - 1],
        series: [
          { name: keys[0], value: values[0] },
          { name: keys[1], value: values[1] },
          { name: keys[2], value: values[2] }
        ]
      };
    });

    return data;
  }

  convertToGoogle(dataToConvert) {
    const data = [];

    data.push(['mes', 'solar', 'consumo', 'red']);

    each(dataToConvert[0].res, (val, prop) => {
      const values = Object.values(val);
      const keys = Object.keys(val);
      const res = [this.months[+prop - 1], values[0], values[1], values[2]];

      data.push(res);
    });

    return data;
  }

  convertToGoogleStacked(dataToConvert) {
    const data = [];

    data.push(['mes', 'AUTOCONSUMIDA', 'EXPORTADA', 'CONSUMIDA']);

    each(dataToConvert[0].res, (val, prop) => {
      const values = Object.values(val);
      const auto = [' ', values[4], values[3], 0];
      const consumed = [this.months[+prop - 1], 0, 0, values[0]];
      const month = [this.months[+prop - 1], 0, 0, 0];
      const separator = [' ', 0, 0, 0];

      data.push(auto);
      // data.push(month);
      data.push(consumed);
      data.push(separator);
    });

    return data;
  }

  htmltoPDF() {
    this.showUploads = false;
    this.calculating = true;

    const pdf = new jsPDF('p', 'pt', [pageWith, pageHeight]);
    // const page1 = pdf.addPage();

    setTimeout(async () => {
      for (let i = 1; i < 6; i++) {
        await this.addImage(pdf, '#page' + i, i);
        await this.addHeader(pdf);
        await this.addFooter(pdf);
      }
      pdf.deletePage(6);
      pdf.save(`informe_${this.data.customerData.opportunity}.pdf`);

      const fileUload = pdf.output('blob');

      this.fileUload(fileUload);

      this.calculating = false;
      this.showUploads = true;
    }, 500);

    // html2canvas(document.getElementById('invoice-panel'), { letterRendering: 1, allowTaint : true, onrendered : function (canvas) { } });

    // parentdiv is the html element which has to be converted to PDF
  }

  fileUload(file) {
    let formData: FormData = new FormData();

    formData.append('file', file, file.name);

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    this.http
      .post(
        environment.api_url + '/api/storage/pdf/' + this.store.budgetId,
        formData,
        {
          headers
        }
      )
      .subscribe(
        data => console.log(data),
        error => console.log(error)
      );
  }

  private async addHeader(pdf: any) {
    const canvas = await html2canvas(document.querySelector('#header'), {
      allowTaint: true,
      useCORS: true
    });
    var imgData = canvas.toDataURL('image/jpeg', 1.0);
    pdf.addImage(imgData, 'PNG', 100, 50, 1350, 130);

    return pdf;
  }

  private async addFooter(pdf: any) {
    const canvas = await html2canvas(document.querySelector('#footer'), {
      allowTaint: true,
      useCORS: true
    });
    var imgData = canvas.toDataURL('image/jpeg', 1.0);
    pdf.addImage(imgData, 'PNG', 460, 2150, 1600, 50);

    return pdf;
  }
  private async addImage(pdf: any, id, pageNum) {
    const canvas = await html2canvas(document.querySelector(id), {
      allowTaint: true,
      useCORS: true
    });

    var imgData = canvas.toDataURL('image/jpeg', 1.0);

    pdf.addPage(pageWith, pageHeight);

    pdf.setPage(pageNum);

    const correctorDim = 200;

    pdf.addImage(
      imgData,
      'PNG',
      150,
      200,
      pageWith - correctorDim - 100,
      pageHeight - correctorDim
    );

    return pdf;
  }

  ngOnInit() {
    this.calculate();
  }

  handleChange({ file, fileList }: UploadChangeParam, image): void {
    const status = file.status;

    if (status === 'done') {
      this.images[image].url = environment.api_url + file.response.url;

      const d3Url =
        environment.api_url + '/uploads/op_d3_' + this.store.budgetId + '.jpg';

      this.updateD3Image(d3Url);

      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }
}
