import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';

declare var google: any;

@Component({
  selector: 'solar-badget-summary-graph-battery',
  templateUrl: './summary-graph-battery.component.html',
  styleUrls: ['./summary-graph-battery.component.scss']
})
export class SummaryGraphBatteryComponent implements OnInit, AfterViewInit {
  multi: any[];
  view: any[] = [1100, 400];

  @ViewChild('pieChart') pieChart: ElementRef;
  /**
   * Datos de las gráficas de consumo
   */
  @Input() data;

  @Input() dataGoogle;

  @Input() radiationData;

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Meses';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Energía';
  yScaleMax: 10000;
  animations: boolean = true;

  colorScheme = {
    domain: ['#59C3E3', '#E9483F', '#C1D100']
  };

  constructor() {}

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  ngOnInit(): void {}

  drawChart = () => {
    const data = google.visualization.arrayToDataTable(this.dataGoogle);

    const options = {
      vAxis: { format: 'decimal' },
      colors: ['#59C3E3', '#E9483F', '#C1D100'],
      series: {
        0: { color: '#59C3E3' },
        1: { color: '#C1D100' },
        2: { color: '#E9483F' }
      },
      isStacked: true
    };

    const chart = new google.charts.Bar(this.pieChart.nativeElement);

    chart.draw(data, google.charts.Bar.convertOptions(options));
  };

  ngAfterViewInit() {
    google.charts.load('current', { packages: ['bar'] });
    google.charts.setOnLoadCallback(this.drawChart);
  }
}
