import { Component, OnInit } from '@angular/core';

import { NzMessageService } from 'ng-zorro-antd';
import { ConfigResponse } from '../rates/rates.component';
import { StoreService } from '../../../shared/store-service.service';

import { find } from 'lodash';
import { ConfigService } from '../config.service';

@Component({
  selector: 'solar-badget-consumption-hourly',
  templateUrl: './consumption-hourly.component.html',
  styleUrls: ['./consumption-hourly.component.scss'],
  providers: [StoreService]
})
export class ConsumptionHourlyComponent implements OnInit {
  residencial = [
    [4, 3, 3, 2, 2, 2, 3, 3, 3, 3, 3, 4, 4, 5, 5, 5, 5, 4, 5, 6, 7, 7, 7, 5],
    [3, 2, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 5, 6, 6, 6, 6, 5, 6, 6, 6, 6, 5, 4]
  ];

  industrial = [
    [2, 2, 2, 2, 2, 3, 5, 5, 6, 6, 6, 6, 6, 6, 5, 5, 5, 5, 4, 4, 4, 3, 3, 3],
    [2, 2, 2, 2, 2, 3, 5, 5, 6, 6, 6, 6, 6, 6, 5, 5, 5, 5, 4, 4, 4, 3, 3, 3]
  ];

  loading = true;

  hourly = {
    name: 'consumption-hourly',
    id: null,
    config: ''
  };

  save() {
    this.hourly.config = JSON.stringify({
      residencial: this.residencial,
      industrial: this.industrial
    });

    if (this.hourly.id) {
      this.consumptionsService.update(this.hourly).subscribe((res: any) => {
        this.message.success('Consumos actualizados');
      });
    } else {
      const obj = {
        name: this.hourly.name,
        config: this.hourly.config
      };

      this.consumptionsService.create(obj).subscribe((res: any) => {
        if (res.config) {
          this.hourly.id = res.config.id;
          this.message.success('Consumos creados');
        }
      });
    }
  }

  updateData(fromData) {
    this[fromData.type] = fromData.data;
  }

  constructor(
    private consumptionsService: ConfigService,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.consumptionsService.get().subscribe((rates: ConfigResponse[]) => {
      if (rates && rates.length) {
        const hourly = find(rates, rate => rate.name === this.hourly.name);
        if (hourly) {
          this.hourly.id = hourly.id;

          const data = JSON.parse(hourly.config);

          this.residencial = data.residencial;
          this.industrial = data.industrial;
        }
      }
      this.loading = false;
    });
  }
}
