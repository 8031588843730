import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  StoreService,
  ConsumptionData
} from '../../../shared/store-service.service';
import { ConfigResponse } from '../../../admin/config/rates/rates.component';
import { isString } from 'lodash';
import { ConfigService } from '../../../admin/config/config.service';
export const CONSUMPTION = 'consumption';
import { find, isObject } from 'lodash';

@Component({
  selector: 'solar-badget-badget-consumption-data',
  templateUrl: './badget-consumption-data.component.html',
  styleUrls: ['./badget-consumption-data.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgetConsumptionDataComponent implements OnInit {
  /**
   * Datos de la pantalla consumos
   */
  consumptionData: ConsumptionData;

  /**
   * Formulario
   */
  consumptionForm: FormGroup;

  /**
   * Muestra los datos de consumo mensuales
   */
  showMonthlyConsumption = true;

  /**
   * Muestra perioxdos de consumo
   */
  showMonthyConsumptionPeriods = true;

  /**
   * Define los periodos seleccionados
   * - Única
   * - Un periodo
   * - Dos periodos
   */
  periodsData = [];

  /**
   * Estaciones de los periodos horarios
   */
  periodsHourlySeasons = [{ name: 'Invierno' }, { name: 'Verano' }];

  /**
   * Datos de las gráficas de consumo
   */
  graphConsumptionData = [];

  /**
   * Datos de las gráficas de consumo horario
   */
  graphHourlyData = [];

  defaultRates;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private ratesService: ConfigService
  ) {}

  ngOnInit() {
    this.consumptionData = {};

    const consumption = this.store.get(CONSUMPTION);
    if (isObject(consumption)) {
      this.consumptionData = consumption;
    }
    this.graphConsumptionData = this.store.get('consumptionPeriod');

    if (this.consumptionData.rateType) {
      this.periodsData = [];
      for (let i = 0; i < this.consumptionData.rateType; i++) {
        this.periodsData.push({ name: 'T' + i });
      }
    }

    this.initForm();

    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges() {
    this.consumptionForm.valueChanges.subscribe(
      (consumptionData: ConsumptionData) => {
        this.periodsHourlySeasons = [];
        this.periodsData = [];
        for (let i = 0; i < consumptionData.rateType; i++) {
          this.periodsData.push({ name: 'T' + i });
        }
        this.updateWithDefaults();
        this.store.setConsumptionData(consumptionData);
      }
    );
  }

  private async initForm() {
    this.consumptionForm = this.fb.group({
      hiredPotency: [this.consumptionData.hiredPotency],
      rateType: [this.consumptionData.rateType, [Validators.required]],
      monthlyConsumption: [1, [Validators.required]],
      hourlyConsumption: [
        this.consumptionData.hourlyConsumption,
        [Validators.required]
      ],
      yearlykWh: [this.consumptionData.yearlykWh],
      p1: [this.consumptionData.p1],
      p2: [this.consumptionData.p2],
      p3: [this.consumptionData.p3],
      salePrice: [this.consumptionData.salePrice],
      increaseConsumption: [this.consumptionData.increaseConsumption || 0]
    });

    this.ratesService.get().subscribe((rates: ConfigResponse[]) => {
      if (rates && rates[0] && isString(rates[0].config)) {
        this.defaultRates = JSON.parse(rates[0].config);
        this.updateWithDefaults();
      }
    });

    this.ratesService.get().subscribe((rates: ConfigResponse[]) => {
      if (rates && rates.length) {
        const rate = find(rates, rate => rate.name === 'rates');
        if (rate) {
          this.defaultRates = JSON.parse(rate.config);
          this.updateWithDefaults();
        }
      }
    });
  }

  updateWithDefaults() {
    if (!this.consumptionData) {
      return;
    }

    if (!this.consumptionData.salePrice && this.defaultRates.salePrice) {
      this.consumptionData.salePrice = this.defaultRates.salePrice;
      this.consumptionForm.patchValue({
        salePrice: this.defaultRates.salePrice
      });
    }
    if (
      !this.consumptionData.p1 &&
      this.defaultRates.p1 &&
      this.periodsData.length > 0
    ) {
      this.consumptionData.p1 = this.defaultRates.p1;
      this.consumptionForm.patchValue({
        p1: this.defaultRates.p1
      });
    }
    if (
      !this.consumptionData.p2 &&
      this.defaultRates.p2 &&
      this.periodsData.length > 1
    ) {
      this.consumptionData.p2 = this.defaultRates.p2;
      this.consumptionForm.patchValue({
        p2: this.defaultRates.p2
      });
    }
    if (
      !this.consumptionData.p3 &&
      this.defaultRates.p3 &&
      this.periodsData.length > 2
    ) {
      this.consumptionData.p3 = this.defaultRates.p3;
      this.consumptionForm.patchValue({
        p3: this.defaultRates.p3
      });
    }

    this.store.setConsumptionData(this.consumptionData);
  }

  formatterPercent = (value: number) => `${value} %`;

  /**
   * Recibe los datos de los periodos de consumo
   * @param data
   */
  setgraphConsumptionData(data) {
    this.graphConsumptionData = data;
    this.store.setConsumptionPeriodGraphData(data);
  }
  /**
   * Recibe los datos de los periodos de consumo
   * @param data
   */
  setgraphConsumptionPeriodData(data) {
    this.store.setConsumptionPeriodData(data);
  }

  cleanAnuals() {
    this.consumptionForm.patchValue({
      yearlykWh: null
    });
  }

  /**
   * Recibe los datos del consumo por horas
   * @param data
   */
  setGraphHourlyData(data) {
    this.graphHourlyData = data;
  }
}
