import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(private http: HttpClient) {}

  get() {
    return this.http.get(environment.api_url + '/api/config');
  }

  create(data) {
    return this.http.post(environment.api_url + '/api/config', data);
  }

  update(data) {
    return this.http.post(
      environment.api_url + '/api/config/' + data.id,
      data
    );
  }
}
