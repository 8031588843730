<solar-badget-badget-actions></solar-badget-badget-actions>
<div style="margin-top: 30px;"></div>
<nz-steps [nzCurrent]="current" (nzIndexChange)="onIndexChange($event)">
  <nz-step nzTitle="DATOS CLIENTE" nzIcon="user"></nz-step>
  <nz-step nzTitle="CONSUMOS" nzIcon="table"></nz-step>
  <nz-step nzTitle="PRODUCCIÓN" nzIcon="bar-chart"></nz-step>
  <nz-step nzTitle="DATOS ECONÓMICOS" nzIcon="bulb"></nz-step>
</nz-steps>
<div [style.height.px]="contentHeigth"  [@openClose]="isOpen ? 'open' : 'closed'" class="steps-content">
  <ng-template step></ng-template>
</div>
<div class="steps-action">
  <button nz-button nzType="default" (click)="pre()" *ngIf="current > 0">
    <span>< ANTERIOR</span>
  </button>
  <button nz-button nzType="default" (click)="next()" *ngIf="current < 3">
    <span>SIGUIENTE ></span>
  </button>
  <button nz-button nzType="primary" (click)="done()" *ngIf="current === 3">
    <span>VER PRESUPUESTO</span>
  </button>
</div>
