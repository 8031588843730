import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, User } from './auth/auth.service';
import { Router } from '@angular/router';
import { Subscription, pipe } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'solar-badget-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  logo = '/assets/imgs/logo-solar.png';

  logo_img = '/assets/imgs/logo-solar-imagotipo.png';

  isCollapsed = false;

  showApp;

  user: User;

  sub: Subscription;

  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.showApp = this.auth.isLoggedIn();

    if (!this.auth.isLoggedIn()) {
      this.logout();

      this.sub = this.auth
        .userChanges()
        .pipe(debounceTime(500))
        .subscribe((user: User) => {
          this.user = user;
          if (user.name) {
            this.router.navigateByUrl('/budget/wizard');
          }
        });
    } else {
      // this.router.navigateByUrl('/budget/wizard');
      this.user = this.auth.user;
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  logout() {
    this.auth.logout();
    // // localStorage.removeItem('budget');
    // localStorage.removeItem('token_type');
    // localStorage.removeItem('expires_in');
    // localStorage.removeItem('user');
    this.router.navigateByUrl('/login');
  }
}
