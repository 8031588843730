<div *ngIf="!loading">
  <nz-table
    #editRowTable
    nzBordered
    [nzData]="listOfData"
    nzShowPagination="false"
    nzTotal="true"
    nzSize="small"
    style="width:1000px"
  >
    <thead>
      <tr>
        <th></th>
        <th *ngFor="let hour of hours">{{ hour }}</th>
        <th>Pctg</th>
        <th>Editar</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of editRowTable.data; let i = index">
        <td>{{ seasons[i] }}</td>
        <td
          (click)="startEdit(i, $event)"
          (keyup)="saveWhileEdit(i)"
          *ngFor="let hour of hours"
        >
          <ng-container *ngIf="!editCache[i].edit; else nameInputTpl">
            {{ data[hour] }}
          </ng-container>
          <ng-template #nameInputTpl>
            <!-- DATA VALUE -->
            <div class="slider">
              <nz-slider
                [nzMin]="1"
                nzVertical
                [nzMax]="10"
                (ngModelChange)="saveWhileEdit(i)"
                [(ngModel)]="editCache[i].data[hour]"
              ></nz-slider>
            </div>
          </ng-template>
        </td>
        <td
          [ngClass]="{
            'hourly-error': total(data) > 100,
            'hourly-ok': total(data) == 100,
            hourly: total(data) < 100
          }"
        >
          {{ total(data) }}%
        </td>
        <td>
          <div class="editable-row-operations">
            <ng-container *ngIf="!editCache[i].edit; else saveTpl">
              <i
                style="cursor: pointer;"
                nzTooltipTitle="editar"
                nzTooltipPlacement="top"
                nz-tooltip
                (click)="startEdit(i)"
                nz-icon
                nzType="edit"
                nzTheme="outline"
              ></i>
            </ng-container>
            <ng-template #saveTpl>
              <i
                style="cursor: pointer;"
                nzTooltipTitle="guardar"
                nzTooltipPlacement="top"
                nz-tooltip
                (click)="saveEdit(i)"
                nz-icon
                nzType="save"
                nzTheme="outline"
              ></i>
            </ng-template>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
