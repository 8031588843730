<!-- index.html -->
<!-- <!DOCTYPE html>
<head>
  ...
  <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY"></script>
</head> -->

<!-- example-app.html -->
<google-map
  [height]="mapDimensions.height"
  [width]="mapDimensions.width"
  [center]="center"
  [zoom]="zoom"
  (mapClick)="mapClick($event)"
  (zoomChanged)="zoomChanged($event)"
>
  <map-info-window></map-info-window>
</google-map>
