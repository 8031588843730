import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GmapsGeocode } from '../../../const';

import { environment } from '../../../../environments/environment';
import {
  LocationData,
  StoreService
} from '../../../shared/store-service.service';
import { Subscription } from 'rxjs';
import { clone } from 'lodash';

@Component({
  selector: 'solar-badget-badget-location',
  templateUrl: './badget-location.component.html',
  styleUrls: ['./badget-location.component.scss']
})
export class BadgetLocationComponent implements OnInit, OnDestroy {
  data: LocationData;

  loadingMap = false;

  locationForm: FormGroup;

  geocode: GmapsGeocode = null;

  geocodeResult: google.maps.GeocoderResult = null;

  latLng: google.maps.LatLngLiteral;

  latLng2: google.maps.LatLngLiteral;

  zoom: number = 16;

  responseType = 'json';

  bufferSearch = null;

  seachByAddress = 'coor';

  sub: Subscription;

  timeOut = null;

  msgLocation = null;

  constructor(
    private fb: FormBuilder,
    private change: ChangeDetectorRef,
    private store: StoreService
  ) {}

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.data = this.store.get('location');

    this.latLng2 = clone(this.store.get('latLng'));
    this.latLng = clone(this.store.get('latLng'));

    this.setMsgLocation();

    if (this.latLng.lat) {
      this.data.lat = this.latLng.lat + '';
      this.data.lng = this.latLng.lng + '';
    }

    this.zoom = this.store.get('zoom');

    this.locationForm = this.fb.group({
      province: [this.data.province, [Validators.required]],
      municipality: [this.data.municipality, [Validators.required]],
      address: [this.data.address],
      lat: [this.data.lat],
      lng: [this.data.lng]
    });

    this.locationForm.valueChanges.subscribe((data: LocationData) => {
      this.store.setLocationData(data);
    });

    this.sub = this.store.state$.subscribe(data => {
      if (
        data.latLngData &&
        data.latLngData.lat &&
        this.latLng2.lat !== +data.latLngData.lat
      ) {
        this.latLng2 = {
          lat: +data.latLngData.lat,
          lng: +data.latLngData.lng
        };
        this.setMsgLocation();
      }
    });
  }

  private setMsgLocation() {
    if (this.latLng2 && this.latLng2.lat) {
      this.msgLocation = `Ubicación de la instalación: ${this.latLng2.lat}, ${this.latLng2.lng}`;
    }
  }

  doSearch() {
    if (this.timeOut) {
      this.timeOut = null;
      return;
    }
    if (this.seachByAddress === 'coor') {
      this.doSearchLatlng();
    } else {
      this.geocodeSearchByAddres();
    }
    this.timeOut = setTimeout(() => {
      this.doSearch();
    }, 1000);
  }

  geocodeSearchByAddres() {
    const geocoder = new google.maps.Geocoder();
    const address = this.getLocation();
    if (!address) {
      return;
    }
    this.startLoading();
    const geocoderRequest = { address };
    geocoder.geocode(
      geocoderRequest,
      (geocode: google.maps.GeocoderResult[], status) => {
        if (geocode && geocode.length) {
          this.geocodeResult = geocode[0];
          this.store.setLocationData({
            lat: this.geocodeResult.geometry.location.lat().toString(),
            lng: this.geocodeResult.geometry.location.lng().toString()
          });

          this.latLng.lat = this.geocodeResult.geometry.location.lat();
          this.latLng.lng = this.geocodeResult.geometry.location.lng();
        }
        this.endLoading();
      }
    );
  }

  startLoading() {
    this.loadingMap = true;
  }

  endLoading() {
    this.loadingMap = false;
    this.change.markForCheck();
  }

  toggleSearchByAddress() {
    // if (!this.seachByAddress) {
    //   this.cleanAddress();
    // } else {
    //   this.cleanLatlng();
    // }
  }

  cleanAddress() {
    this.locationForm.patchValue({
      province: null,
      municipality: null,
      address: null
    });
  }

  cleanLatlng() {
    this.locationForm.patchValue({
      lat: null,
      lng: null
    });
  }

  centerChanges(center) {
    this.latLng.lat = center.lat;
    this.latLng.lng = center.lng;
    this.store.setLatLng(center);
    this.store.setLocationData(center);
  }

  zoomChanges(zoom) {
    this.zoom = zoom;
    this.store.setZoom(zoom);
  }

  doSearchLatlng() {
    const geocoder = new google.maps.Geocoder();
    const { lat, lng } = this.getLatLng();

    if (!lat || !lng) {
      return false;
    }

    this.startLoading();
    const location = new google.maps.LatLng(+lat, +lng);
    const geocoderRequest = { location };

    geocoder.geocode(
      geocoderRequest,
      (geocode: google.maps.GeocoderResult[], status) => {
        if (geocode && geocode.length) {
          this.geocodeResult = geocode[0];

          this.store.setLocationData({
            lat: this.geocodeResult.geometry.location.lat().toString(),
            lng: this.geocodeResult.geometry.location.lng().toString()
          });
        }
        this.endLoading();
      }
    );
  }

  private getLocation() {
    const values: LocationData = this.locationForm.value;

    if (!values.address && !values.municipality && !values.province) {
      return null;
    }

    return `${values.address} ${values.province} ${values.municipality}`;
  }

  private getLatLng() {
    const values: LocationData = this.locationForm.value;

    if (!values.lat || !values.lng) {
      return { lat: null, lng: null };
    }
    this.zoom = 22;
    this.centerChanges({ lat: values.lat, lng: values.lng });

    return { lat: values.lat, lng: values.lng };
  }
}
