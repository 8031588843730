<div
  style="margin-left:100px"
  *ngFor="let financialData of dynamicFinancialData; let i = index"
>
  <solar-badget-financial-option
    (onRemove)="remove($event)"
    (onChange)="change($event)"
    [index]="i"
    [financialData]="financialData"
  ></solar-badget-financial-option>
</div>

<div style="margin: 40px 0">
  <button (click)="add()" nz-button>
    <i nz-icon nzType="plus"></i> Añadir
  </button>
  <button
    nzType="primary"
    style="margin-left: 10px;"
    (click)="save()"
    nz-button
  >
    <i nz-icon nzType="save"></i> Guardar
  </button>
</div>
