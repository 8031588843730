import { Component, OnInit } from '@angular/core';

import { find } from 'lodash';
import { ConfigService } from '../config.service';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { ConfigResponse } from '../rates/rates.component';

@Component({
  selector: 'solar-badget-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss']
})
export class FinancialComponent implements OnInit {
  dynamicFinancialData = [];

  loading = true;

  financial = {
    name: 'financial',
    config: null,
    id: null
  };

  add() {
    this.dynamicFinancialData.push({
      month: 0,
      tae: 0,
      tin: 0,
      coef: 0
    });
  }

  remove(index) {
    console.log(index);
    this.dynamicFinancialData.splice(index, 1);
  }

  change({ index, data }) {
    this.dynamicFinancialData[index] = data;
  }

  save() {
    this.financial.config = JSON.stringify(this.dynamicFinancialData);

    if (this.financial.id) {
      this.configService.update(this.financial).subscribe((res: any) => {
        this.message.success('Datos financieros actualizados');
      });
    } else {
      const obj = {
        name: this.financial.name,
        config: this.financial.config
      };
      this.configService.create(obj).subscribe((res: any) => {
        if (res.config) {
          this.financial.id = res.config.id;
          this.message.success('Tarifas creadas');
        }
      });
    }
  }

  constructor(
    private fb: FormBuilder,
    private configService: ConfigService,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.configService.get().subscribe((datum: ConfigResponse[]) => {
      if (datum && datum.length) {
        const financial = find(
          datum,
          data => data.name === this.financial.name
        );
        if (financial) {
          this.dynamicFinancialData = JSON.parse(financial.config);
          this.financial.id = financial.id;
        }
        this.loading = false;
      }
    });
  }
}
