import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ConfigService } from '../config.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Subscription, pipe } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'solar-badget-financial-option',
  templateUrl: './financial-option.component.html',
  styleUrls: ['./financial-option.component.scss']
})
export class FinancialOptionComponent implements OnInit, OnDestroy {
  @Input() financialData: {
    month: number;
    tae: number;
    tin: number;
    coef: number;
  };

  removeItem = false;

  @Output() onRemove = new EventEmitter();

  @Output() onChange = new EventEmitter();

  @Input() index;

  sub: Subscription;

  financialForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  activateRemove() {
    this.removeItem = true;

    setTimeout(() => {
      this.removeItem = false;
    }, 5000);
  }

  remove() {
    this.onRemove.emit(this.index);
  }

  ngOnInit(): void {
    this.financialForm = this.fb.group({
      month: [this.financialData.month, [Validators.required]],
      tae: [this.financialData.tae, [Validators.required]],
      tin: [this.financialData.tin, [Validators.required]],
      coef: [this.financialData.coef, [Validators.required]]
    });

    this.sub = this.financialForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(data => {
        this.onChange.emit({ index: this.index, data });
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
