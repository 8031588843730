<div>
  <button
    (click)="add()"
    nz-button
    nzType="primary"
    nzSize="large"
    nzShape="round"
  >
    <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
  </button>
  <div
    nz-col
    nzSpan="2"
    style="
  top: 50px;
  position: absolute;
  right: 45px;"
  >
    <solar-badget-user-avatar></solar-badget-user-avatar>
  </div>
</div>
<nz-table [nzShowPagination]="false" #basicTable [nzData]="users" [nzLoading]="loading" >
  <thead>
    <tr>
      <th width="90">Id</th>
      <th width="500">Nombre</th>
      <th width="300">Email</th>
      <th width="300">Perfil</th>
      <th width="90">Activo</th>
      <th width="300">Creado</th>
      <th></th>
      <th width="120"></th>
      <th width="120"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of basicTable.data">
      <td>{{ user.id }}</td>
      <td>{{ user.name }}</td>
      <td>{{ user.email }}</td>
      <td>{{ user.role }}</td>
      <td>
        <span style="color:#388e3c" *ngIf="user.active">Activo</span>
        <span style="color:#455a64" *ngIf="!user.active">Inactivo</span>
      </td>
      <td>{{ user.created_at }}</td>
      <td></td>
      <td>
        <button style="width: 120px;" (click)="edit(user)" nz-button>
          <span>Editar</span>
        </button>
      </td>
      <td>
        <button style="width: 120px;" (click)="active(user)" nz-button>
          <span *ngIf="!user.active">Activar</span>
          <span *ngIf="user.active">Desactivar</span>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
<nz-modal
  [(nzVisible)]="isVisible"
  [nzFooter]="nzFooter"
  nzTitle="Crear/Editar Usuario"
  (nzOnCancel)="cancel()"
>
  <form
    nz-form
    [formGroup]="validateForm"
    (ngSubmit)="update(validateForm.value)"
  >
    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Usuario</nz-form-label>
      <nz-form-control
        [nzSpan]="12"
        nzHasFeedback
        nzValidatingTip="Validating..."
        [nzErrorTip]="userErrorTpl"
      >
        <input
          nz-input
          formControlName="name"
          placeholder="Nombre del usuario"
        />
        <ng-template #userErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Please input your name!
          </ng-container>
          <ng-container *ngIf="control.hasError('duplicated')">
            El usuario ya existe!
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Email</nz-form-label>
      <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="emailErrorTpl">
        <input
          nz-input
          formControlName="email"
          placeholder="email"
          type="email"
        />
        <ng-template #emailErrorTpl let-control>
          <ng-container *ngIf="control.hasError('email')">
            The input is not valid E-mail!
          </ng-container>
          <ng-container *ngIf="control.hasError('required')">
            Please input your E-mail!
          </ng-container>
          <ng-container *ngIf="control.hasError('duplicated')">
            El mail ya existe!
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Teléfono</nz-form-label>
      <nz-form-control
        [nzSpan]="12"
        nzHasFeedback
        nzValidatingTip="Validating..."
        [nzErrorTip]="userErrorTpl"
      >
        <input
          nz-input
          formControlName="phone"
          placeholder="Teléfono del usuario"
        />
        <ng-template #userErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Please input your name!
          </ng-container>
          <ng-container *ngIf="control.hasError('duplicated')">
            The name is redundant!
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="9">Perfil</nz-form-label>
      <nz-form-control [nzSpan]="12">
        <nz-select formControlName="role">
          <nz-option
            *ngFor="let role of roles"
            [nzValue]="role.value"
            [nzLabel]="role.name"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="9">Contraseña</nz-form-label>
      <nz-form-control
        [nzSpan]="12"
        nzHasFeedback
        nzErrorTip="Please input your password!"
      >
        <input
          nz-input
          type="password"
          formControlName="password"
          (ngModelChange)="validateConfirmPassword()"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="9">Confirmar Contraseña</nz-form-label>
      <nz-form-control
        [nzSpan]="12"
        nzHasFeedback
        [nzErrorTip]="passwordErrorTpl"
      >
        <input
          nz-input
          type="password"
          formControlName="confirm"
          placeholder="Confirmar Contraseña"
        />
        <ng-template #passwordErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Please confirm your password!
          </ng-container>
          <ng-container *ngIf="control.hasError('confirm')">
            Password is inconsistent!
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Activo</nz-form-label>
      <nz-form-control [nzSpan]="12">
        <nz-switch formControlName="active"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzOffset]="7" [nzSpan]="12">
        <button
          style="width: 100px;"
          nz-button
          nzType="primary"
          [nzLoading]="isConfirmLoading"
          [disabled]="!validateForm.valid"
        >
          <i nz-icon nzType="save" nzTheme="outline"></i> Guardar
        </button>
        <button style="width: 100px;" nz-button (click)="resetForm($event)">
          <i nz-icon nzType="clear" nzTheme="outline"></i> Limpiar
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>
