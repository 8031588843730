import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as shape from 'd3-shape';

@Component({
  selector: 'solar-badget-consumption-hourly-graph',
  templateUrl: './badget-consumption-hourly-graph.html',
  styleUrls: ['./badget-consumption-hourly-graph.css']
})
export class BadgetConsumptionHourlyGraph implements OnInit, OnChanges {
  @Input() data;

  multi: any[];
  view: any[] = [1000, 400];

  _data = [
    {
      name: 'Invierno',
      series: []
    },
    {
      name: 'Verano',
      series: []
    }
  ];

  // options
  legend = true;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Hora del día';
  yAxisLabel = 'Consumo';
  timeline = true;
  yScaleMin = 0;
  yScaleMax = 10;
  curve = shape.curveBasis;

  ngOnInit() {
    // this.defaultSeries();
  }

  ngOnChanges(changes) {
    this.data = changes.data.currentValue;
    // this.fillValueSerie(changes.data.currentValue);
  }

  colorScheme = {
    domain: ['#E9483F', '#FCC358']
  };

  constructor() {}

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
