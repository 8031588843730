<nz-spin *ngIf="loading" nzSimple></nz-spin>
<div *ngIf="!loading" nz-row>
  <div nz-col nzSpan="12">
    <form style="margin-left: 2%;" nz-form [formGroup]="installationForm">
      <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>Inversión</nz-form-label>
        <nz-form-control [nzSpan]="5">
          <nz-input-group [nzSuffix]="euroSuffix">
            <input
              nz-input
              formControlName="investment"
              placeholder="Inversión"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="7">Financiación</nz-form-label>
        <nz-form-control [nzSpan]="2">
          <nz-select formControlName="financing" nzPlaceHolder="Financiación">
            <nz-option
              *ngFor="let financingOption of financingOptions"
              [nzValue]="financingOption"
              [nzLabel]="financingOption.label"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="installationForm.value.financing?.value === 1">
        <nz-form-label [nzSpan]="7" nzRequired>Meses</nz-form-label>
        <nz-form-control [nzSpan]="3">
          <nz-select formControlName="months">
            <nz-option
              *ngFor="let option of dynamicFinancialData"
              [nzValue]="option"
              [nzLabel]="option.month"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="installationForm.value.financing?.value === 1">
        <nz-form-label [nzSpan]="7">Entrada</nz-form-label>
        <nz-form-control [nzSpan]="3">
          <nz-input-group nzSuffix="€">
            <input nz-input formControlName="entry" placeholder="Entrada" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="installationForm.value.financing?.value === 1">
        <nz-form-label [nzSpan]="7">Mantenimiento anual</nz-form-label>
        <nz-form-control [nzSpan]="5">
          <nz-input-group [nzSuffix]="euroSuffix">
            <input
              nz-input
              formControlName="maintenance"
              placeholder="Mantenimiento anual"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="installationForm.value.financing?.value === 1">
        <nz-form-label [nzSpan]="7">Comisión de apertura</nz-form-label>
        <nz-form-control [nzSpan]="3">
          <nz-input-number
            formControlName="openingCommission"
            [nzMin]="0"
            [nzMax]="2"
            [nzFormatter]="formatterPercent"
            [nzStep]="0.1"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="installationForm.value.financing?.value === 1">
        <nz-form-label [nzSpan]="7">Entidad financiera</nz-form-label>
        <nz-form-control [nzSpan]="7">
          <nz-input-group>
            <input
              style="text-align: left;"
              nz-input
              formControlName="financialEntity"
              placeholder="Entidad financiera"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="installationForm.value.financing?.value === 1">
        <nz-form-label [nzSpan]="7">Coeficiente de cuota</nz-form-label
        >
        <nz-form-control [nzSpan]="3">
          <nz-select
            formControlName="financingRatio"
            nzPlaceHolder="Coeficiente"
          >
            <nz-option
              *ngFor="let option of dynamicFinancialData"
              [nzValue]="option"
              [nzLabel]="option.coef"
            ></nz-option>
          </nz-select>
          <!-- <nz-input-group>
            <nz-input-number
              style="width: 120px;"
              formControlName="financingRatio"
              [nzMin]="0"
              [nzMax]="10"
              [nzFormatter]="formatterPercent"
              [nzStep]="0.00005"
            ></nz-input-number>
          </nz-input-group> -->
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="installationForm.value.financing?.value === 1">
        <nz-form-label [nzSpan]="7">TAE</nz-form-label>
        <nz-form-control [nzSpan]="3">
          <nz-input-group [nzSuffix]="pctgSuffix">
            <input
              readonly="readonly"
              nz-input
              formControlName="tae"
              placeholder="tae"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="installationForm.value.financing?.value === 1">
        <nz-form-label [nzSpan]="7">TIN</nz-form-label>
        <nz-form-control [nzSpan]="3">
          <nz-input-group [nzSuffix]="pctgSuffix">
            <input
              readonly="readonly"
              nz-input
              formControlName="tin"
              placeholder="tae"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <!-- <nz-form-item *ngIf="installationForm.value.financing?.value === 1">
        <nz-form-label [nzSpan]="7" nzRequired>Interés</nz-form-label>
        <nz-form-control [nzSpan]="2">
          <nz-input-number
            formControlName="interests"
            [nzMin]="1"
            [nzMax]="25"
            [nzStep]="0.1"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item> -->
    </form>
  </div>
  <div nz-col nzSpan="12">
    <ng-container *ngIf="installationForm.value.financing?.value === 1">
      <button nz-button (click)="calculate()" nzType="primary">
        Calcular
      </button>
      <div class="text-summary">
        <ul>
          <li>
            <span class="important"> {{ financialData.cm.name }} </span>:
            {{ financialData.cm.value | number: '3.1-2':'es' }} €
          </li>
          <li>
            <span class="important">
              {{ financialData.numberOfFees.name }} </span
            >: {{ installationForm.value.months?.month }}
          </li>
          <li>
            <span class="important">
              {{ financialData.initialEntry.name }} </span
            >: {{ installationForm.value.entry }} €
          </li>
        </ul>
      </div>
      <div class="text-summary">
        En
        <span class="important">{{ tplData.financialData?.months.month }}</span>
        cuotas. Entrada
        <span class="important">{{
          tplData.financialData?.entry | number: '3.1-2':'es'
        }}</span>
        €. TAE: {{ tplData.financialData?.tae }} % P.V.P. para P.V.P de
        <span class="important">{{
          financialData.pvp.value | number: '3.1-2':'es'
        }}</span>
        € (Incluye equipos, materiales, mano de obra, puesta en marcha,
        legalizaciones y servicio de mantenimiento, impuestos incluidos)
        financiando con
        <span class="important">{{
          tplData.financialData?.financialEntity
        }}</span>
        (según condiciones contractuales). Sujeto a aprobación financiera.
        Comisión de formalización
        <span class="important">{{
          financialData.cf.value | number: '3.1-2':'es'
        }}</span>
        €. Importe a financiar:
        <span class="important">{{
          financialData.if.value | number: '3.1-2':'es'
        }}</span>
        €. Precio total a plazos
        <span class="important">{{
          financialData.ptp.value | number: '3.1-2':'es'
        }}</span>
        €. Importe total adeudado
        <span class="important">{{
          financialData.ita.value | number: '3.1-2':'es'
        }}</span>
        €. TIN:
        <span class="important">{{ tplData.financialData?.tin }} </span>%
      </div>
    </ng-container>
  </div>
</div>
