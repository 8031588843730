<nz-modal
  [(nzVisible)]="isVisible"
  [nzFooter]="null"
  nzClosable="false"
  nzMaskClosable="false"
  nzTitle="Elivere"
  nzWidth="300"
>
  <form
    nz-form
    [formGroup]="validateForm"
    class="login-form"
    (ngSubmit)="login()"
  >
    <nz-form-item>
      <nz-form-control nzErrorTip="Please input your username!">
        <nz-input-group nzPrefixIcon="user">
          <input
            type="text"
            nz-input
            formControlName="username"
            placeholder="Usuario"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control nzErrorTip="Please input your Password!">
        <nz-input-group nzPrefixIcon="lock">
          <input
            type="password"
            nz-input
            formControlName="password"
            placeholder="Contraseña"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <button
          [nzLoading]="checkingCretendials"
          nz-button
          class="login-form-button"
          [nzType]="'primary'"
        >
          Log in
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>
