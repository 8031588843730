import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { each, find, map, clone } from 'lodash';
import {
  StoreService,
  BadgetData
} from '../../../shared/store-service.service';
import { Subscription } from 'rxjs';
import { ConfigResponse } from '../../../admin/config/rates/rates.component';
import { ConfigService } from '../../../admin/config/config.service';

interface ItemData {
  id: string;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'solar-badget-consumption-hourly-data-period',
  templateUrl: './badget-consumption-hourly-data.component.html',
  styleUrls: ['./badget-consumption-hourly-data.component.css']
})
export class BadgetConsumptionHourlyDataComponent
  implements OnInit, OnChanges, OnDestroy {
  /**
   * Estaciones de los periodos horarios
   */
  @Input() periodsHourlySeasons;

  /**
   * Estaciones de los periodos horarios
   */
  @Input() data;

  /**
   * Emite los datos de consumo Horario
   */
  @Output() hourlyData: EventEmitter<any> = new EventEmitter();

  private _periodsHourlySeasons = [];

  validateForm: FormGroup;

  editCache: { [key: string]: { edit: boolean; data: ItemData } } = {};

  listOfData: any[] = [];

  seasons = ['Invierno', 'Verano'];

  residencial = [];

  profiles = [];

  industrial = [];

  installationType = 'residencial';

  hours = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24'
  ];

  sub: Subscription;

  loading = true;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private consumptionsService: ConfigService
  ) {
    this.profiles = this[this.store.getData().installationType];

    this.consumptionsService.get().subscribe((rates: ConfigResponse[]) => {
      if (rates && rates.length) {
        const hourly = find(rates, rate => rate.name === 'consumption-hourly');
        if (hourly) {
          const data = JSON.parse(hourly.config);

          this.residencial = data.residencial;
          this.industrial = data.industrial;
          this.profiles = this[this.store.getData().installationType];
          this.loading = false;
          this.setData();
        }
      }
      this.loading = false;
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnChanges(changes) {
    if (
      !changes.periodsHourlySeasons.previousValue ||
      (changes.periodsHourlySeasons.currentValue.length !==
        changes.periodsHourlySeasons.previousValue.length &&
        changes.periodsHourlySeasons.currentValue.length)
    ) {
      this._periodsHourlySeasons = changes.periodsHourlySeasons.currentValue;
      this.setData();
    }
  }

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  total(data) {
    let total = 0;
    each(this.hours, hour => {
      total += +data[hour];
    });
    return +total.toFixed(0);
  }

  globalTotal() {
    let total = 0;
    each(this.listOfData, data => {
      total += +this.total(data);
    });
    return total.toFixed(2);
  }

  hourTotal(month) {
    let total = 0;
    each(this.listOfData, data => {
      const a = find(data, d => d.month === month);
      total += +data[month];
    });
    return total.toFixed(2);
  }

  cancelEdit(id: string): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: { ...this.listOfData[index] },
      edit: false
    };
  }

  saveWhileEdit(id: string): void {
    setTimeout(() => {
      Object.assign(this.listOfData[id], this.editCache[id].data);
      this.updateGraphData();
    }, 200);
  }

  saveEdit(id: string): void {
    Object.assign(this.listOfData[id], this.editCache[id].data);
    this.editCache[id].edit = false;
    this.updateGraphData();
  }

  updateEditCache(): void {
    this.listOfData.forEach((item, index) => {
      this.editCache[index] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  updateGraphData(): void {
    const graphData: any = [];

    this.listOfData.forEach((item, index) => {
      const series = map(item, (value, name) => {
        return {
          name,
          value: +value || 0
        };
      });

      graphData.push({
        name: this.seasons[index],
        series
      });
    });

    this.store.setConsumptionHourlyData(this.listOfData);

    this.hourlyData.emit(graphData);
  }

  getRandom() {
    return (Math.random() * 300).toFixed(2);
  }

  ngOnInit(): void {
    this.installationType = this.store.getData().installationType
    console.log(this.installationType);
    this.sub = this.store.state$.subscribe((badgetData: BadgetData) => {
      if (
        this.store.getData().installationType &&
        this.installationType !== this.store.getData().installationType
      ) {
        this.profiles = this[this.store.getData().installationType];
        this.installationType = this.store.getData().installationType;

        this.store.setConsumptionHourlyData([]);

        this.setData();
      }
    });
  }

  getData() {
    const dataFromStore = this.store.get('consumptionHourly', []);

    if (dataFromStore.length) {
      return dataFromStore;
    }

    return [];
  }

  private setData() {
    if (this.loading) {
      return;
    }

    const consumptionHourlyData = this.getData();

    this.listOfData = clone(consumptionHourlyData);

    for (
      let i = consumptionHourlyData.length;
      i < this._periodsHourlySeasons.length;
      i++
    ) {
      const listOfData = {};

      for (let j = 0; j < 24; j++) {
        listOfData[this.hours[j]] = this.profiles[i][j];
      }
      this.listOfData.push(listOfData);
    }

    this.updateEditCache();
    this.updateGraphData();
  }
}
