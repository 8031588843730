import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { StoreService } from '../../shared/store-service.service';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { get } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

export interface BudgetCreationResponse {
  message: string;
  budget: Budget;
}

export interface Budget {
  opportunity: string;
  budget: string;
  customer: string;
  address: string;
  updated_at: string;
  created_at: string;
  id: number;
}

@Component({
  selector: 'solar-badget-badget-actions',
  templateUrl: './badget-actions.component.html',
  styleUrls: ['./badget-actions.component.scss']
})
export class BadgetActionsComponent implements OnInit, OnDestroy {
  confirmModal: NzModalRef; // For testing by now

  storeEmpty = true;

  sub: Subscription;

  updateTime;

  canSave = false;

  canView = false;

  saving = false;

  constructor(
    private store: StoreService,
    private modal: NzModalService,
    private http: HttpClient,
    private message: NzMessageService,
    private change: ChangeDetectorRef,
    private me: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sub = this.store.state$.subscribe(data => {
      if (!data.customerData) {
        this.storeEmpty = true;
        return;
      }

      this.canView = this.store.canView();

      const canSave = this.store.canSave();
      this.canSave = canSave.result;

      if (
        !data.customerData.address ||
        !data.customerData.name ||
        !data.customerData.opportunity
      ) {
        this.storeEmpty = true;
        return;
      }

      this.storeEmpty = data.current === -1;
    });
  }
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  viewBudget() {
    if (!this.canView) {
      this.message.warning(
        'Faltan datos para generar el informe: ' +
          this.store.viewSummaryErrors[0]
      );
      return;
    }

    this.store.saveRemote(this.http).subscribe((res: any) => {
      if (get(res, 'budget.id', null)) {
        this.store.setBudgetId(res.budget.id);
      }

      this.router.navigateByUrl('/budget/view');
    });
  }

  newBudget(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Nuevo Presupuesto',
      nzContent: 'Se van a limpiar todos los datos.',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.store.save();
          this.store.reset();
          resolve();
        }).catch(() => console.log('Oops errors!'))
    });
  }

  exportBudget() {}

  saveBudget(): void {
    if (this.saving) {
      return;
    }

    const data = this.store.getData();

    const canSave = this.store.canSave();

    this.canSave = canSave.result;

    if (!canSave.result) {
      this.message.error(canSave.error);
      return;
    }

    this.saving = true;

    if (data.id) {
      this.http
        .post(environment.api_url + '/api/budgets/' + data.id, {
          opportunity: data.customerData.opportunity,
          budget: JSON.stringify(data),
          customer: data.customerData.name
            ? data.customerData.name + ' ' + data.customerData.surname
            : '',
          address: data.customerData.address,
        })
        .subscribe(
          (res: BudgetCreationResponse) => {
            this.saving = false;
            this.change.markForCheck();
            this.message.success('Presupuesto actualizado');
          },
          ({ error }) => {
            this.saving = false;
            this.change.markForCheck();
            if (error) {
              this.message.error(error.message);
            }
          }
        );
    } else {
      this.http
        .post(environment.api_url + '/api/budgets', {
          opportunity: data.customerData.opportunity,
          budget: JSON.stringify(data),
          user_id: this.me.user.id,
          customer: data.customerData.name
            ? data.customerData.name + ' ' + data.customerData.surname
            : '',
          address: data.customerData.address
        })
        .subscribe(
          (res: BudgetCreationResponse) => {
            if (get(res, 'budget.id')) {
              this.store.setBudgetId(get(res, 'budget.id'));
            }
            this.saving = false;
            this.change.markForCheck();
            this.message.success('Presupuesto creado');
          },
          ({ error }) => {
            this.saving = false;
            this.change.markForCheck();

            if (error) {
              this.message.error(error.message);
            }
          }
        );
    }
  }

  // onKeyDown($event): void {
  //   // Detect platform
  //   if (navigator.platform.match('Mac')) {
  //     this.handleMacKeyEvents($event);
  //   } else {
  //     this.handleWindowsKeyEvents($event);
  //   }
  // }

  // handleMacKeyEvents($event) {
  //   // MetaKey documentation
  //   const charCode = String.fromCharCode($event.which).toLowerCase();
  //   if ($event.metaKey && charCode === 's') {
  //     // Action on Cmd + S
  //     $event.preventDefault();
  //   }
  // }

  // handleWindowsKeyEvents($event) {
  //   const charCode = String.fromCharCode($event.which).toLowerCase();
  //   if ($event.ctrlKey && charCode === 's') {
  //     // Action on Ctrl + S
  //     $event.preventDefault();
  //   }
  // }
}
