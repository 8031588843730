<ngx-charts-line-chart
  [view]="view"
  [scheme]="colorScheme"
  [legend]="legend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxis]="xAxis"
  [yAxis]="yAxis"
  [xAxisLabel]="xAxisLabel"
  [results]="data"
  [curve]="curve"
  [yScaleMin]="yScaleMin"
  [yScaleMax]="yScaleMax"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"

>
</ngx-charts-line-chart>
