import { Injectable } from '@angular/core';
export interface CustomerData {
  name?: string;
  surname?: string;
  address?: string;
  contact?: string;
  opportunity?: string;
  title?: string;
  comment?: string;
}

export interface ConsumptionData {
  hiredPotency?: number;
  rateType?: number;
  monthlyConsumption?: string;
  contact?: string;
  hourlyConsumption?: string;
  yearlykWh?: number;
  p1?: any;
  p2?: any;
  p3?: any;
  salePrice?: any;
  increaseConsumption?: number;
}

export interface LocationData {
  province?: string;
  municipality?: string;
  address?: string;
  lat?: string;
  lng?: string;
}

export interface BadgetMainData {
  title: string;
  date: Date;
}

export interface FinancingOptions {
  label: 'Sí' | 'No';
  value: 0 | 1;
}

export interface BadgetFinancingData {
  investment: number;
  entry?: number;
  maintenance?: number;
  months?: any;
  openingCommission?: number;
  financialEntity?: string;
  financingRatio?: any;
  tae?: number;
  tin?: number;

  // deprecated
  interests?: number;
  financing?: FinancingOptions;
}

export interface SlopeOption {
  value: 1 | 2;
  label: string;
}

export interface SlopeData {
  id: number;
  installed_peak_power?: number;
  inclination?: number;
  azimut?: number;
}

export interface BadgetData {
  id?: number;
  customerData?: CustomerData;
  current?: number;
  badget?: BadgetMainData;
  consumptionData?: ConsumptionData;
  locationData?: LocationData;
  latLngData?: google.maps.LatLngLiteral;
  consumptionPeriodData?: any[];
  consumptionHourlyData?: any[];
  zoomData?: number;
  bateryData?: any;
  financingData?: BadgetFinancingData;
  financialResults?: FinanancialResults;
  slopes?: SlopeData[];
  installationType?: string;
}

import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FinanancialResults } from '../solar-badget/badget-steps/badget-installation/badget-installation.component';
import { map } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private _current = 0;

  private badgetData = {
    title: 'Presupuesto 1',
    date: new Date()
  };

  customerData: CustomerData;

  budgetId;

  consumptionData: ConsumptionData = {
    hiredPotency: null,
    rateType: null,
    monthlyConsumption: null,
    contact: null,
    hourlyConsumption: null,
    yearlykWh: null,
    p1: null,
    p2: null,
    p3: null,
    increaseConsumption: 0
  };

  private locationData: LocationData = {
    province: null,
    municipality: null,
    address: null
  };

  consumptionPeriodData = [];
  consumptionPeriodGraphData = [];

  consumptionHourlyData = [];
  consumptionHourlyGraphData = [];

  financialData: BadgetFinancingData;
  financialResults: FinanancialResults;

  slopesData: SlopeData[];

  bateryData;

  viewSummaryErrors = [];

  installationType = '';

  private latLngData: google.maps.LatLngLiteral = {
    lat: 41.65646513050207,
    lng: -0.8971238136291504
  };

  private zoomData = 10;

  state$: BehaviorSubject<BadgetData>;

  constructor() {
    this.state$ = new BehaviorSubject({ customerData: this.customerData });
    if (localStorage.getItem('budget')) {
      this.setData(JSON.parse(localStorage.getItem('budget')));
      this.propagate();
    }
  }

  saveRemote(http: HttpClient) {
    const data = this.getData();
    if (data.id) {
      return http.post(environment.api_url + '/api/budgets/' + data.id, {
        opportunity: data.customerData.opportunity,
        budget: JSON.stringify(data),
        customer: data.customerData.name
          ? data.customerData.name + ' ' + data.customerData.surname
          : '',
        address: data.customerData.address
      });
    } else {
      return http.post(environment.api_url + '/api/budgets', {
        opportunity: data.customerData.opportunity,
        budget: JSON.stringify(data),
        customer: data.customerData.name
          ? data.customerData.name + ' ' + data.customerData.surname
          : '',
        address: data.customerData.address
      });
    }
  }

  set current(current) {
    this._current = current;
    this.save();
  }

  get current() {
    return this._current || 0;
  }

  setBudgetId(id: number) {
    if (id) {
      this.budgetId = id;
    }
  }

  setBadgetData(badgetData: BadgetMainData) {
    this.badgetData = badgetData;
  }

  setLocationData(locationData: LocationData) {
    this.locationData = locationData;
    this.propagate();
  }

  setLatLng(latLng: google.maps.LatLngLiteral) {
    this.latLngData = latLng;
    this.propagate();
  }

  setZoom(zoom: number) {
    this.zoomData = zoom;
    this.propagate();
  }

  setCustomerData(customerData: CustomerData) {
    this.customerData = customerData;
    this.propagate();
  }

  setConsumptionData(consumptionData: ConsumptionData) {
    if (consumptionData && consumptionData.salePrice) {
      consumptionData.salePrice = +consumptionData.salePrice
        .toString()
        .replace(',', '.');
    }
    if (consumptionData && consumptionData.hiredPotency) {
      consumptionData.hiredPotency = +consumptionData.hiredPotency
        .toString()
        .replace(',', '.');
    }
    if (consumptionData && consumptionData.yearlykWh) {
      consumptionData.yearlykWh = +consumptionData.yearlykWh
        .toString()
        .replace(',', '.');
    }
    if (consumptionData && consumptionData.p1) {
      consumptionData.p1 = consumptionData.p1.replace(',', '.');
    }
    if (consumptionData && consumptionData.p2) {
      consumptionData.p2 = consumptionData.p2.replace(',', '.');
    }
    if (consumptionData && consumptionData.p3) {
      consumptionData.p3 = consumptionData.p3.replace(',', '.');
    }

    this.consumptionData = consumptionData;
    this.propagate();
  }

  setConsumptionPeriodData(consumptionPeriodData: any) {
    this.consumptionPeriodData = consumptionPeriodData;
    this.propagate();
  }

  setConsumptionPeriodGraphData(consumptionPeriodGraphData: any) {
    this.consumptionPeriodGraphData = consumptionPeriodGraphData;
  }

  setConsumptionHourlyData(consumptionHourlyData: any) {
    this.consumptionHourlyData = consumptionHourlyData;
    this.propagate();
  }

  setConsumptionHourlyGraphData(consumptionHourlyData: any) {
    this.consumptionHourlyGraphData = consumptionHourlyData;
  }

  setFinancialData(financingData: BadgetFinancingData) {
    if (financingData.financingRatio) {
      financingData.financingRatio = +financingData.financingRatio
        .toString()
        .replace(',', '.');
    }
    if (financingData.interests) {
      financingData.interests = +financingData.interests
        .toString()
        .replace(',', '.');
    }
    if (financingData.openingCommission) {
      financingData.openingCommission = +financingData.openingCommission
        .toString()
        .replace(',', '.');
    }
    if (financingData.entry) {
      financingData.entry = +financingData.entry.toString().replace(',', '.');
    }
    if (financingData.maintenance) {
      financingData.maintenance = +financingData.maintenance.toString().replace(',', '.');
    }
    if (financingData.tae) {
      financingData.tae = +financingData.tae.toString().replace(',', '.');
    }
    if (financingData.tin) {
      financingData.tin = +financingData.tin.toString().replace(',', '.');
    }

    this.financialData = financingData;
    this.propagate();
  }

  setFinancialResults(financialResults: FinanancialResults) {
    this.financialResults = financialResults;
    this.propagate();
  }

  setSlopes(slopes: SlopeData[]) {
    // debugger;
    slopes = map(slopes, (slope: SlopeData) => {
      if (slope.installed_peak_power) {
        slope.installed_peak_power = +slope.installed_peak_power
          .toString()
          .replace(',', '.');
      }
      if (slope.inclination) {
        slope.inclination = +slope.inclination.toString().replace(',', '.');
      }
      if (slope.azimut) {
        slope.azimut = +slope.azimut.toString().replace(',', '.');
      }

      return slope;
    });

    this.slopesData = slopes;
    this.propagate();
  }

  setBattery(bateryData) {
    this.bateryData = bateryData;
    this.propagate();
  }

  save() {
    localStorage.setItem('budget', JSON.stringify(this.getData()));
  }

  setInstallationType(type) {
    console.log('setInstallationType', type);
    this.installationType = type;
    this.propagate();
  }

  reset(current = null) {
    if (!current) {
      this.state$.next({ current: -1 });
      localStorage.removeItem('budget');
      this.budgetId = null;
    }
    this.clearData();
    setTimeout(() => {
      this.state$.next({ current: current ? current : 0 });
    }, 200);
  }

  canView() {
    this.viewSummaryErrors = [];

    if (!this.customerData || !this.customerData.opportunity) {
      this.viewSummaryErrors.push('Oportunidad');
      return false;
    }

    if (!this.latLngData || !this.latLngData.lat || !this.latLngData.lng) {
      this.viewSummaryErrors.push('Posición en mapa');
      return false;
    }

    if (
      !this.slopesData ||
      !this.slopesData[0].inclination ||
      !this.slopesData[0].installed_peak_power
    ) {
      this.viewSummaryErrors.push('Vertientes');
      return false;
    }

    if (!this.consumptionPeriodData || !this.consumptionPeriodData.length) {
      this.viewSummaryErrors.push('Datos de consumo');
      return false;
    }

    if (!this.consumptionHourlyData || !this.consumptionHourlyData.length) {
      this.viewSummaryErrors.push('Datos de consumo diario');
      return false;
    }

    if (!this.consumptionData || !this.consumptionData.p1) {
      this.viewSummaryErrors.push('P1');
      return false;
    }

    if (+this.consumptionData.rateType > 1 && !this.consumptionData.p2) {
      this.viewSummaryErrors.push('P2');
      return false;
    }

    if (+this.consumptionData.rateType > 2 && !this.consumptionData.p3) {
      this.viewSummaryErrors.push('P3');
      return false;
    }

    if (!this.consumptionData.hiredPotency) {
      this.viewSummaryErrors.push('Potencia Contratada');
      return false;
    }

    if (!this.consumptionData.salePrice) {
      this.viewSummaryErrors.push('Precio de compensación');
      return false;
    }

    if (!this.financialData || !this.financialData.investment) {
      this.viewSummaryErrors.push('Inversión');
      return false;
    }

    return true;
  }

  canSave() {
    let save = {
      result: true,
      error: null
    };

    if (!this.customerData) {
      this.customerData = {};
    }

    if (!this.customerData.name) {
      return {
        result: false,
        error: 'Introduce Nombre'
      };
    }

    if (!this.customerData.surname) {
      return {
        result: false,
        error: 'Introduce Apellidos'
      };
    }

    if (!this.customerData.address) {
      return {
        result: false,
        error: 'Introduce Dirección'
      };
    }

    if (!this.customerData.opportunity) {
      return {
        result: false,
        error: 'Introduce Oportunidad'
      };
    }

    return save;
  }

  canAdd() {}

  getData(): BadgetData {
    return {
      id: this.budgetId,
      current: this.current,
      badget: this.badgetData,
      customerData: this.customerData,
      consumptionData: this.consumptionData,
      locationData: this.locationData,
      latLngData: this.latLngData,
      consumptionPeriodData: this.consumptionPeriodData,
      consumptionHourlyData: this.consumptionHourlyData,
      zoomData: this.zoomData,
      financingData: this.financialData,
      financialResults: this.financialResults,
      bateryData: this.bateryData,
      slopes: this.slopesData,
      installationType: this.installationType
    };
  }

  get(type, defaultValue = null) {
    return this[type + 'Data'] || defaultValue || {};
  }

  clearData() {
    this.setBudgetId(null);
    this.setCustomerData({ address: '', name: '' });
    this.setConsumptionData(null);
    this.setBadgetData(null);
    this.setLocationData(null);
    this.current = null;
    this.latLngData = null;
    this.consumptionPeriodData = null;
    this.consumptionHourlyData = null;
    this.zoomData = null;
    this.bateryData = null;
    this.financialData = null;
    this.financialResults = null;
    this.slopesData = null;
    this.installationType = 'residencial';
  }

  setData(data: BadgetData) {
    if (data.current < 0) {
      return;
    }
    this.setBudgetId(data.id);
    this.setCustomerData(data.customerData);
    this.setConsumptionData(data.consumptionData);
    this.setBadgetData(data.badget);
    this.setLocationData(data.locationData);
    this.current = data.current;
    this.bateryData = data.bateryData;
    this.latLngData = data.latLngData || this.latLngData;
    this.consumptionPeriodData = data.consumptionPeriodData;
    this.consumptionHourlyData = data.consumptionHourlyData;
    this.zoomData = data.zoomData;
    this.financialData = data.financingData;
    this.financialResults = data.financialResults;
    this.slopesData = data.slopes;
    this.installationType = data.installationType;
    this.propagate();
  }

  propagate() {
    this.save();
    this.state$.next(this.getData());
  }
}
