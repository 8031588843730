import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SlopeData } from '../../../shared/store-service.service';

@Component({
  selector: 'solar-badget-badget-installation-slopes',
  templateUrl: './badget-installation-slopes.component.html',
  styleUrls: ['./badget-installation-slopes.component.scss']
})
export class BadgetInstallationSlopesComponent implements OnInit {
  @Input() slopeData: SlopeData;
  @Input() num: number;

  @Output() slopeChanges = new EventEmitter();

  slopeForm: FormGroup;
  suffixIconButton = 'save';
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.slopeForm = this.fb.group({
      installed_peak_power: [
        this.slopeData.installed_peak_power,
        [Validators.required]
      ],
      inclination: [this.slopeData.inclination, [Validators.required]],
      azimut: [this.slopeData.azimut, [Validators.required]]
    });

    this.slopeForm.valueChanges.subscribe(changes => {
      this.slopeData = Object.assign(this.slopeData, changes);
      this.slopeChanges.emit(this.slopeData);
    });
  }
}
