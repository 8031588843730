import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GmapsComponent } from './gmaps/gmaps.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ConsumptionHourlyDataComponent } from './consumption-hourly-data/consumption-hourly-data.component';
import { UIModule } from '../app.ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserComponent } from './user/user.component';

@NgModule({
  declarations: [GmapsComponent, ConsumptionHourlyDataComponent, UserComponent],
  imports: [CommonModule, GoogleMapsModule, UIModule, FormsModule, ReactiveFormsModule],
  exports: [GmapsComponent, ConsumptionHourlyDataComponent],
  entryComponents: [GmapsComponent]
})
export class SharedModule {}
