import { Component, OnInit, Input } from '@angular/core';

export var multi = [
  {
    name: 'Solar',
    series: [
      {
        name: '2010',
        value: 7300000
      },
      {
        name: '2011',
        value: 8940000
      }
    ]
  }
];

@Component({
  selector: 'solar-badget-summary-graph-battery-pctg',
  templateUrl: './summary-graph-battery-pctg.component.html',
  styleUrls: ['./summary-graph-battery-pctg.component.scss']
})
export class SummaryGraphBatteryPctgComponent implements OnInit {
  multi: any[];
  view: any[] = [500, 60];

  @Input() data;

  graphData = [];

  // options
  showXAxis: boolean = false;
  showYAxis: boolean = false;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  yAxisLabel: string;
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'Population';

  consumedPctg = 0;
  exportedPctg = 0;

  colorScheme = {
    domain: ['#59C3E3', '#C1D100']
  };

  constructor() {}

  onSelect(event) {
    console.log(event);
  }

  ngOnInit(): void {
    const data = this.data[0];

    const ahorro = data.totalDemandaKwYear - data.importadoDeLaRed;

    this.consumedPctg = (ahorro * 100) / data.totalproductionKwYear;

    this.exportedPctg = 100 - this.consumedPctg;

    this.yAxisLabel = `Solar ${data.solarTotal} kWh`;

    this.graphData = [
      {
        name: `Solar ${data.producido} kWh`,
        series: [
          {
            name: `Consumido ${data.ahorroAnual} kWh`,
            value: this.consumedPctg
          },
          {
            name: `Exportado ${data.vertidoAnual} kWh`,
            value: this.exportedPctg
          }
        ]
      }
    ];
  }
}
