import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(public auth: AuthService, public router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let access_token = localStorage.getItem('access_token');

    if (req.url.includes('auth/login')) {
      return next.handle(req);
    } else if (access_token && !this.auth.isLoggedOut()) {
      let cloned = req;

      if (access_token) {
        access_token = access_token.replace(/^\"/, '').replace(/\"$/, '');

        cloned = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + access_token)
        });
      }
      return next.handle(cloned);
    } else {
      window.location.reload();
    }
  }
}
