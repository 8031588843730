<div class="title">ENERGÍA ESTIMADA MENSUAL</div>
<!-- {{data | json}} -->
<!-- <ngx-charts-bar-vertical-stacked
  [view]="view"
  [scheme]="colorScheme"
  [results]="data"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [animations]="animations"
  (select)="onSelect($event)"
>
</ngx-charts-bar-vertical-stacked> -->

<!-- {{ dataGoogle | json }} -->
<div #pieChart style="width: 1200px; height: 500px"></div>

<div
  style="display: inline-block; margin-bottom: 10px; margin-top:70px;"
  *ngIf="data"
>
  <div style="height: 50%; display: inline-block">
    <solar-badget-summary-graph-battery-pctg
      [data]="radiationData"
    ></solar-badget-summary-graph-battery-pctg>
  </div>
  <div style="height: 50%;display: inline-block; margin-left: 200px;">
    <solar-badget-summary-graph-consumption-pctg [data]="radiationData">
    </solar-badget-summary-graph-consumption-pctg>
  </div>
</div>
