import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BadgetComponent } from './solar-badget/badget/badget.component';
import { BadgetHistoryComponent } from './solar-badget/badget-history/badget-history.component';
import { LoginComponent } from './auth/login/login.component';
import { BadgetResultsComponent } from './solar-badget/badget-steps/badget-results/badget-results.component';
import { UsersComponent } from './users/users/users.component';
import { ConfigComponent } from './admin/config/config.component';

const routes: Routes = [
  { path: 'users', component: UsersComponent },
  { path: 'config', component: ConfigComponent },
  { path: 'login', component: LoginComponent },
  { path: 'budget/history', component: BadgetHistoryComponent },
  { path: 'budget/wizard/:id', component: BadgetComponent },
  { path: 'budget/wizard', component: BadgetComponent },
  { path: 'budget/view', component: BadgetResultsComponent },
  { path: '*', pathMatch: 'full', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
