import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'solar-badget-summary-graph-financial',
  templateUrl: './summary-graph-financial.component.html',
  styleUrls: ['./summary-graph-financial.component.scss']
})
export class SummaryGraphFinancialComponent implements OnInit {
  @Input() data;

  single: any[];
  view: any[] = [600, 400];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  graphData;

  label = 'Balance energético anual en euros';

  colorScheme = {
    domain: ['#59C3E3', '#E9483F', '#C1D100']
  };

  constructor() {}

  euroFormatting = (value: number) => `${value} €`;

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  valueFormatting(data) {
    return `${data} €. Importado de la red.`;
  }

  ngOnInit(): void {
    this.graphData = [
      {
        name: 'Compensado',
        value: this.data.compensado
      },
      {
        name: 'Facturado',
        value: this.data.importado - this.data.compensado
      }
    ];
  }
}
