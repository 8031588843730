import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  ValidationErrors
} from '@angular/forms';
import { Observable, Observer, Subscription } from 'rxjs';
import {
  StoreService,
  CustomerData
} from '../../../shared/store-service.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'solar-badget-badget-customer',
  templateUrl: './badget-customer.component.html',
  styleUrls: ['./badget-customer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgetCustomerComponent implements OnInit, OnDestroy {
  data: CustomerData;

  validateForm: FormGroup;

  sub: Subscription;

  isVisible;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private change: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.data = this.store.get('customer');

    this.validateForm = this.fb.group({
      name: [this.data.name, [Validators.required]],
      surname: [this.data.surname, [Validators.required]],
      address: [this.data.address, [Validators.required]],
      contact: [this.data.contact, [Validators.required]],
      opportunity: [this.data.opportunity, [Validators.required]],
      comment: [this.data.comment, [Validators.required]],
      title: [this.data.title, [Validators.required]]
    });

    this.validateForm.valueChanges.subscribe(customerData => {
      this.store.setCustomerData(customerData);
    });

    this.sub = this.store.state$.subscribe(state => {
      if (state.current === -1) {
        this.validateForm.reset();
        setTimeout(() => {
          this.change.markForCheck();
        }, 1000);
      }
    });

    if (!this.data.opportunity) {
      this.isVisible = true;
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  handleOk(): void {
    setTimeout(() => {
      this.isVisible = false;
    }, 3000);
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  userNameAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        if (control.value === 'JasonWood') {
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          observer.next(null);
        }
        observer.complete();
      }, 1000);
    });
}
