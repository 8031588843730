import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import { MapInfoWindow, GoogleMap } from '@angular/google-maps';
import { GmapsGeocode } from '../../const';
import { get } from 'lodash';
@Component({
  selector: 'solar-badget-gmaps',
  templateUrl: './gmaps.component.html',
  styleUrls: ['./gmaps.component.scss']
})
export class GmapsComponent implements OnChanges, OnInit, AfterViewInit {
  @Output() centerChanges = new EventEmitter();
  @Output() zoomChanges = new EventEmitter();
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  @Input() geocode: GmapsGeocode;
  @Input() geocodeResult: google.maps.GeocoderResult;
  @Input() latLng: google.maps.LatLngLiteral;
  @Input() zoom: number;

  @Input() width: string;
  @Input() height: string;

  marker;

  center: google.maps.LatLngLiteral = {
    lat: 41.65646513050207,
    lng: -0.8971238136291504
  };

  mapDimensions = {
    width: '1000px',
    height: '600px'
  };

  ngOnInit() {
    if (this.latLng && this.latLng.lat) {
      this.latLng.lat = +this.latLng.lat;
      this.latLng.lng = +this.latLng.lng;
    }
  }

  ngAfterViewInit(): void {
    this.center = this.latLng;
    this.addMarker(this.latLng);
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: <any>['polygon']
      },
      polygonOptions: {
        fillOpacity: 0.1,
        strokeWeight: 5,
        clickable: true,
        editable: true,
        draggable: true,
        geodesic: true,
        zIndex: 1
      }
    });

    this.mapDimensions = {
      width: this.width || this.mapDimensions.width,
      height: this.height || this.mapDimensions.height
    };

    drawingManager.setMap(this.map._googleMap);
  }

  ngOnChanges(changes) {
    if (get(changes, 'geocode.currentValue')) {
      const geocode: GmapsGeocode = changes.geocode.currentValue;
      this.center = get(geocode, 'results[0].geometry.location') || this.center;
    } else if (get(changes, 'width.currentValue')) {
      this.mapDimensions.width = get(changes, 'width.currentValue');
    } else if (get(changes, 'height.currentValue')) {
      this.mapDimensions.height = get(changes, 'height.currentValue');
    } else if (get(changes, 'latLng.currentValue')) {
      this.center = get(changes, 'latLng.currentValue');
    } else if (get(changes, 'geocodeResult.currentValue')) {
      const geocodeResult: google.maps.GeocoderResult =
        changes.geocodeResult.currentValue;
      this.center =
        {
          lat: geocodeResult.geometry.location.lat(),
          lng: geocodeResult.geometry.location.lng()
        } || this.center;
    }
  }

  addMarker(latLng) {
    if (this.marker) {
      this.marker.setMap(null);
    }

    this.marker = new google.maps.Marker({
      position: latLng,
      map: this.map._googleMap,
      title: 'Coordenadas'
    });
  }

  mapClick(event: google.maps.MouseEvent) {
    this.addMarker(event.latLng);
    this.center = get(event, 'latLng');
    setTimeout(() => {
      this.centerChanges.emit(event.latLng.toJSON());
    }, 500);
    event.stop();
  }

  zoomChanged(_zoom) {
    if (!this.map) {
      return;
    }
    this.zoomChanges.emit((<any>this.map._googleMap).zoom);
  }
}
