import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ComponentFactoryResolver,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { StepDirective } from '../../shared/step.directive';
import { BadgetCustomerComponent } from '../badget-steps/badget-customer/badget-customer.component';
import { BadgetConsumptionDataComponent } from '../badget-steps/badget-consumption-data/badget-consumption-data.component';
import { BadgetProductionComponent } from '../badget-steps/badget-production/badget-production.component';
import { BadgetInstallationComponent } from '../badget-steps/badget-installation/badget-installation.component';
import { StoreService } from '../../shared/store-service.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../../../environments/environment';
import { BadgetResultsComponent } from '../badget-steps/badget-results/badget-results.component';
import * as moment from 'moment';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

export interface BudgetsResult {
  id: number;
  opportunity: string;
  budget: string;
  customer: string;
  address: string;
  doc: null;
  deleted: number;
  created_at: string;
  updated_at: string;
}

@Component({
  selector: 'solar-badget-badget',
  templateUrl: './badget.component.html',
  styleUrls: ['./badget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          opacity: 1
        })
      ),
      state(
        'closed',
        style({
          opacity: 0
        })
      ),
      transition('open => closed', [animate('.5s')]),
      transition('closed => open', [animate('5s')])
    ])
  ]
})
export class BadgetComponent implements OnInit, OnDestroy {
  @ViewChild(StepDirective, { static: true }) step: StepDirective;

  current;

  contentHeigth;

  summaryHeigth;

  index = 'First-content';

  private sub: Subscription;

  updateTime;

  components = [
    BadgetCustomerComponent,
    BadgetConsumptionDataComponent,
    BadgetProductionComponent,
    BadgetInstallationComponent
    // BadgetResultsComponent
  ];

  isOpen = true;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private store: StoreService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private message: NzMessageService,
    private change: ChangeDetectorRef
  ) {}

  toggle() {
    this.isOpen = !this.isOpen;
  }

  ngOnInit() {
    this.current = this.store.current;

    this.sub = this.store.state$.subscribe(data => {
      if (-1 === data.current) {
        if (this.current > 0) {
          this.current = 0;
        }
        setTimeout(() => {
          if (this.store.budgetId) {
            this.message.success('Presupuesto recuperado');
          }

          this.changeContent();
          this.change.markForCheck();
        }, 100);
      }
    });

    this.store.current = 0;
    this.current = 0;
    this.changeContent();

    if (this.route.snapshot.params.id) {
      this.http
        .get(
          environment.api_url + '/api/budgets/' + this.route.snapshot.params.id
        )
        .subscribe((budgetResult: BudgetsResult) => {
          this.store.reset(this.store.current);

          const budget = JSON.parse(budgetResult.budget);
          this.store.setData(budget);

          this.store.setBudgetId(this.route.snapshot.params.id);

          setTimeout(() => {
            this.change.detectChanges();
          });

          this.updateTime = moment();
        });
    }

    this.onResize({});
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onIndexChange(index: number): void {
    this.current = index;
    this.changeContent();
  }

  onResize(_evt) {
    this.contentHeigth =
      document.querySelector('.inner-content').getBoundingClientRect().height -
      210;
    this.summaryHeigth =
      document.querySelector('.inner-content').getBoundingClientRect().height -
      200;
  }

  private loadComponent() {
    const viewContainerRef = this.step.viewContainerRef;
    viewContainerRef.clear();

    const component = this.components[this.current];
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<
      any
    >(component);

    viewContainerRef.createComponent(componentFactory);
  }

  pre(): void {
    this.current -= 1;
    this.changeContent();
  }

  next(): void {
    this.current += 1;
    this.changeContent();
  }

  update() {
    this.store.saveRemote(this.http).subscribe((res: any) => {
      // if (!this.updateTime || moment().diff(this.updateTime) > 60 * 10000) {
      //   this.message.info('datos actualizados');
      // }

      this.store.setBudgetId(res.budget.id);

      this.updateTime = moment();
    });
  }

  done(): void {
    this.router.navigateByUrl('/budget/view');
  }

  changeContent(): void {
    this.store.current = this.current;
    this.loadComponent();

    if (this.store.canSave().result) {
      this.update();
    }

    switch (this.current) {
      case 0: {
        this.index = 'First-content';
        break;
      }
      case 1: {
        this.index = 'Second-content';
        break;
      }
      case 2: {
        this.index = 'third-content';
        break;
      }
      default: {
        this.index = 'error';
      }
    }
  }
}
