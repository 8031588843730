import { Component, OnInit, Input } from '@angular/core';
import { QuoteSummary } from '../badget-results/quote-results.type';
import {
  StoreService,
  BadgetData
} from '../../../shared/store-service.service';
import { PVPResults } from '../badget-results/badget-results.component';

@Component({
  selector: 'solar-badget-quote-info',
  templateUrl: './quote-info.component.html',
  styleUrls: ['./quote-info.component.scss']
})
export class QuoteInfoComponent implements OnInit {
  @Input() radiationResults: QuoteSummary;
  @Input() badgetData: BadgetData;
  @Input() pvp: PVPResults;

  constructor(private store: StoreService) {}

  ngOnInit(): void {}

  precioFinal(precio) {
    if (this.store.installationType === 'residencial') {
      return precio * 1.21;
    }

    return precio;
  }

  clienteFinal() {
    return this.store.installationType === 'residencial';
  }
}
