<nz-tabset>
  <nz-tab nzTitle="Precios">
    <solar-badget-rates></solar-badget-rates>
  </nz-tab>
  <nz-tab nzTitle="Consumo">
    <solar-badget-consumption-hourly></solar-badget-consumption-hourly>
  </nz-tab>
  <nz-tab nzTitle="Financiación">
    <solar-badget-financial></solar-badget-financial>
  </nz-tab>
</nz-tabset>
