import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../../const';
import { environment } from '../../../environments/environment';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  users;
  loading= false;
  sub = null;

  constructor(private http: HttpClient) {
    this.allUsers().subscribe(users=>{
      console.log(users);
      this.users = users;
    })
  }

  allUsers() {
    if (this.users) {
      console.log('recuperando de cache');
      return new Observable((subs: Subscriber<any>) => {
        subs.next(this.users);
        subs.complete();
      });
    } else {
      return new Observable((subs: Subscriber<any>) => {
        console.log('pidiendo a DB');
        this.all().subscribe(users => {
          this.users = users;
          subs.next(this.users);
          subs.complete();
        });
      });
    }
  }

  all() {
    return this.http.get<User[]>(environment.api_url + '/api/users');
  }

  update(user: User) {
    return this.http.post(environment.api_url + '/api/user/' + user.id, user);
  }

  toggleActive(user: User) {
    return this.http.get(environment.api_url + '/api/user/active/' + user.id);
  }
}
