import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'solar-badget-summary-graph-consumption-pctg',
  templateUrl: './summary-graph-consumption-pctg.component.html',
  styleUrls: ['./summary-graph-consumption-pctg.component.scss']
})
export class SummaryGraphConsumptionPctgComponent implements OnInit {
  view: any[] = [500, 60];

  @Input() data;

  graphData = [];

  // options
  showXAxis: boolean = false;
  showYAxis: boolean = false;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  yAxisLabel: string;
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'Population';

  importedPctg = 0;
  autoPctg = 0;

  colorScheme = {
    domain: ['#59C3E3', '#E9483F']
  };

  constructor() {}

  onSelect(event) {
    console.log(event);
  }

  ngOnInit(): void {
    const data = this.data[0];

    const ahorro = data.totalDemandaKwYear - data.importadoDeLaRed;

    this.autoPctg = (ahorro * 100) / data.totalDemandaKwYear;

    this.importedPctg = 100 - this.autoPctg;

    this.yAxisLabel = `Solar ${data.producido} kWh`;

    this.graphData = [
      {
        name: `Consumido ${data.consumido} kWh`,
        series: [
          {
            name: `Auto consumo ${data.ahorroAnual} kWh`,
            value: data.ahorroAnual
          },
          {
            name: `Importado ${data.importadoDeLaRed} kWh`,
            value: data.importadoDeLaRed
          }
        ]
      }
    ];
  }
}
