import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgetComponent } from './badget/badget.component';
import { StepDirective } from '../shared/step.directive';
import { BadgetHistoryComponent } from './badget-history/badget-history.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BadgetCustomerComponent } from './badget-steps/badget-customer/badget-customer.component';
import { BadgetConsumptionDataComponent } from './badget-steps/badget-consumption-data/badget-consumption-data.component';
import { BadgetConsumptionDataPeriodComponent } from './badget-steps-components/badget-consumption-data-period/badget-consumption-data-period.component';
import { BadgetGraphPeriodComponent } from './badget-steps-components/badget-graph-period/badget-graph-period.component';
import { BadgetProductionComponent } from './badget-steps/badget-production/badget-production.component';
import { BadgetInstallationComponent } from './badget-steps/badget-installation/badget-installation.component';
import { BadgetResultsComponent } from './badget-steps/badget-results/badget-results.component';
import { BadgetConsumptionHourlyGraph } from './badget-steps-components/badget-consumption-hourly-graph/badget-consumption-hourly-graph';
import { BadgetConsumptionHourlyDataComponent } from './badget-steps-components/badget-consumption-hourly-data/badget-consumption-hourly-data.component';
import { StoreService } from '../shared/store-service.service';
import { BadgetActionsComponent } from './badget-actions/badget-actions.component';
import { SharedModule } from '../shared/shared.module';
import { BadgetInstallationSlopesComponent } from './badget-steps-components/badget-installation-slopes/badget-installation-slopes.component';
import { BadgetLocationComponent } from './badget-steps/badget-location/badget-location.component';
import { SummaryGraphNoBatteryComponent } from './badget-steps-components/summary-graph-no-battery/summary-graph-no-battery.component';
import { UIModule } from '../app.ui';
import { SummaryGraphBatteryComponent } from './badget-steps-components/summary-graph-battery/summary-graph-battery.component';
import { SummaryGraphFinancialComponent } from './badget-steps-components/summary-graph-financial/summary-graph-financial.component';
import { SummaryGraphAmortizationComponent } from './badget-steps-components/summary-graph-amortization/summary-graph-amortization.component';
import { SummaryGraphBatteryPctgComponent } from './badget-steps-components/summary-graph-battery-pctg/summary-graph-battery-pctg.component';
import { UsersModule } from '../users/users.module';
import { SummaryGraphConsumptionPctgComponent } from './badget-steps-components/summary-graph-consumption-pctg/summary-graph-consumption-pctg.component';
import { SummaryGraphFinancialYearlyComponent } from './badget-steps-components/summary-graph-financial-yearly/summary-graph-financial-yearly.component';
import { BadgetResultHeaderComponent } from './badget-steps/badget-results/badget-result-header/badget-result-header.component';
import { BadgetResultFooterComponent } from './badget-steps/badget-results/badget-result-footer/badget-result-footer.component';
import { PieGraphComponent } from './badget-steps-components/pie-graph/pie-graph.component';
import { QuoteInfoComponent } from './badget-steps/quote-info/quote-info.component';

@NgModule({
  declarations: [
    BadgetComponent,
    BadgetCustomerComponent,
    BadgetLocationComponent,
    StepDirective,
    BadgetHistoryComponent,
    BadgetConsumptionDataComponent,
    BadgetConsumptionDataPeriodComponent,
    BadgetConsumptionHourlyDataComponent,
    BadgetGraphPeriodComponent,
    BadgetConsumptionHourlyGraph,
    BadgetProductionComponent,
    BadgetInstallationComponent,
    BadgetResultsComponent,
    BadgetActionsComponent,
    BadgetInstallationSlopesComponent,
    SummaryGraphNoBatteryComponent,
    SummaryGraphBatteryComponent,
    SummaryGraphFinancialComponent,
    SummaryGraphAmortizationComponent,
    SummaryGraphBatteryPctgComponent,
    SummaryGraphConsumptionPctgComponent,
    SummaryGraphFinancialYearlyComponent,
    BadgetResultHeaderComponent,
    BadgetResultFooterComponent,
    PieGraphComponent,
    QuoteInfoComponent
  ],
  exports: [BadgetComponent, BadgetHistoryComponent, BadgetConsumptionHourlyDataComponent],
  imports: [
    CommonModule,
    UIModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    SharedModule,
    UsersModule
  ],
  entryComponents: [
    BadgetCustomerComponent,
    BadgetConsumptionDataPeriodComponent,
    BadgetConsumptionDataComponent,
    BadgetProductionComponent,
    BadgetLocationComponent,
    BadgetInstallationComponent,
    BadgetResultsComponent
  ],
  providers: [StoreService]
})
export class SolarBadgetModule {}
